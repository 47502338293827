<template>
  <div class="tab billAcceptor">
    <div class="container">
      <v-row>
        <!-- Bill Acceptor Manager-->
        <v-col xs='12' sm='12' md='12' lg='7' xl='8'>
          <div class="card card-blue" v-if="data" data-aos="fade">
            <div class="card_header">
              <span class="float-left">Bill Acceptor Manager</span>
              <span class="float-right text-blue">
                  <add-new-item
                    :label='addBtnLabel'
                  @openSpecificCanAdd='openAddBill'
                  />
              </span>
              <div class='pageSizeMenu_container float-right'>
                <v-menu content-class="pageMenu" offset-y left max-width='190' data-aos="fade">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="white" fab x-small dark v-bind="attrs" v-on="on" :elevation='0'>
                            <span class="sort">{{page.size}}</span>
                            <v-icon class="menu_icon">mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in pageSizes" :key="index" v-ripple @click="setSort(item)">
                            <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div class='float-right'>Items per page:</div>
            </div>
            <crud-table-select class="gen_table" :key='1' :data='data.data' :fields='billFields' :canEdit="true" :canDelete="true" :canSelect='true'  @edit="openEditBillModal" @delete="openDeleteModal" @selectItem="getBillChannels"></crud-table-select>
            <div class="text-center table-pagination">
              <v-pagination
              v-model="page.index"
              :length="numberOfPages"
              :total-visible="6"
              color='#1976d2'
              @input='getBillAcceptors(page)'
              ></v-pagination>
            </div>
          </div>
        </v-col>

        <!-- Bill Acceptor Channels-->
        <v-col xs='12' sm='12' md='12' lg='5' xl='4'>
          <div :class="{'card-grayed': !billSelected}" class="card card-blue">
            <div class="card_header">
              <span class="float-left">Channel</span>
              <span class="float-right text-blue">
                  <add-new-item
                    :label='addBtnLabel'
                  @openSpecificCanAdd='openAddChannel'
                  />
              </span>
            </div>
            <crud-table class="gen_table" v-if='selectedBill' :data='selectedBill.billAcceptorChannels' :fields='channelFields' :canEdit="true" :canDelete="true" @edit="openEditChannelModal" @delete="openDeleteModal"></crud-table>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="600">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p v-if='event'>Bill Acceptor</p>
                        <p v-if='!event'>Channel</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class='cabinet_modal'>
             
                        <!-- Add/Edit Bill Acceptor -->
                        
                        <v-row justify='center' v-if='event'> 
                            <v-col cols='8' >
                                <form>
                                    <br>
                                    <div class="fields">
                                        <div class="field" v-if='!temp.id'>
                                          <v-text-field 
                                            label="Name"
                                            v-model="temp.name"
                                            :rules="[() => !!temp.name || 'This field is required']"
                                            ref='name'
                                            outlined
                                          />
                                          <v-select
                                            label='Country'
                                            :items="countries"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.countryId"
                                            :rules="[() => !!temp.countryId || 'This field is required']"
                                            ref='country'
                                            outlined
                                          />
                                          <v-select
                                            label='Manufacturer'
                                            :items="deviceManufacturers"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.manufacturerId"
                                            :rules="[() => !!temp.manufacturerId || 'This field is required']"
                                            ref='manufacturer'
                                            outlined
                                          />
                                          <v-select
                                            label='EGM Manufacturer'
                                            :items="egmManufacturers"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.egmManufacturerId"
                                            :rules="[() => !!temp.egmManufacturerId || 'This field is required']"
                                            ref='egmManufacturer'
                                            outlined
                                          />
                                        </div>
                                        <div class="field" v-if='temp.id'>
                                          <v-text-field 
                                            label="Name"
                                            v-model="temp.name"
                                            :rules="[() => !!temp.name || 'This field is required']"
                                            ref='name'
                                            outlined
                                          />
                                          <v-select
                                            label='Country'
                                            :items="countries"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.countryId"
                                            :rules="[() => !!temp.countryId || 'This field is required']"
                                            ref='country'
                                            outlined
                                          />
                                          <v-select
                                            label='Manufacturer'
                                            :items="deviceManufacturers"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.manufacturerId"
                                            :rules="[() => !!temp.manufacturerId || 'This field is required']"
                                            ref='manufacturer'
                                            outlined
                                          />
                                          <v-select
                                            label='EGM Manufacturer'
                                            :items="egmManufacturers"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.egmManufacturerId"
                                            :rules="[() => !!temp.egmManufacturerId || 'This field is required']"
                                            ref='egmManufacturer'
                                            outlined
                                          />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>

                        <!-- Add/Edit Channel -->

                        <v-row justify='center' v-if='!event'> 
                            <v-col cols='8' >
                                <form>
                                    <br>
                                    <div class="fields">
                                        <div class="field" v-if='!temp.id'>
                                          <v-text-field 
                                            label="Channel"
                                            v-model="temp.channel"
                                            :rules="[() => !!temp.channel || 'This field is required']"
                                            ref='channel'
                                            outlined
                                          />
                                          <v-text-field 
                                            label="Value"
                                            v-model="temp.value"
                                            :rules="[() => !!temp.value || 'This field is required']"
                                            ref='value'
                                            outlined
                                          />
                                          <v-checkbox 
                                            label="Enabled" 
                                            v-model="temp.isEnabled"
                                            ref='isEnabled'
                                            style='margin-bottom:8px; margin-top: 0px'
                                          />
                                        </div> 
                                        <div class="field" v-if='temp.id'>
                                          <v-text-field 
                                            label="Channel"
                                            v-model="temp.channel"
                                            :rules="[() => !!temp.channel || 'This field is required']"
                                            ref='channel'
                                            outlined
                                          />
                                          <v-text-field 
                                            label="Value"
                                            v-model="temp.value"
                                            :rules="[() => !!temp.value || 'This field is required']"
                                            ref='value'
                                            outlined
                                          />
                                          <v-checkbox 
                                            label="Enabled" 
                                            v-model="temp.isEnabled"
                                            ref='isEnabled'
                                            style='margin-bottom:8px; margin-top: 0px'
                                          />
                                        </div>
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>

                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>

                        <!-- Buttons for Add Bill Acceptor -->
                        <v-btn class="modal_btn"  :elevation="0" v-if='event && !temp.id' color="green" dark @click="createBill(temp)">
                            Add New
                        </v-btn>
                        <v-btn class="modal_btn"  :elevation="0" v-if='event && temp.id' color="green" dark @click="updateBill(temp)">
                            Save
                        </v-btn>


                        
                        <!-- Buttons for Add Channel -->
                        <v-btn class="modal_btn"  :elevation="0" v-if='!event && !temp.id' color="green" dark @click="createChannel(temp)">
                            Add New
                        </v-btn>
                        <v-btn class="modal_btn"  :elevation="0" v-if='!event && temp.id' color="green" dark @click="updateChannel(temp)">
                            Save
                        </v-btn>
                       
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p v-if='event'>Bill Acceptor</p>
                        <p v-if='!event'>Channel</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" v-if='event' color="red" dark @click="deleteBill(selected)">
                            Delete
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" v-if='!event' color="red" dark @click="deleteChannel(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
  </div>
</template>

<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTableSelect from '@/components/tables/CrudTableSelect.vue';
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
  components: {
        'add-new-item': AddNewItem,
        'crud-table-select':CrudTableSelect,
        'crud-table':CrudTable,
    },
    data() {
      return {
        data:null, 
        countries:null,
        deviceManufacturers:null,
        egmManufacturers:null,
        billFields:[
          {
            label: 'Country',
            getValue: item => item.country.name,
            type: 'text',
            key:'country',
            ref: 'country',
            required:true,
          },
          {
            label: 'EGM Manufacturer',
            getValue: item => item.egmManufacturer.name,
            type: 'text',
            key:'egmManufacturer',
            ref: 'egmManufacturer',
            required:true,
          },
          {
            label: 'Manufacturer',
            getValue: item => item.manufacturer.name,
            type: 'text',
            key:'manufacturer',
            ref: 'manufacturer',
            required:true,
          },
          {
            label: 'Name',
            getValue: item => item.name,
            type: 'text',
            key:'name',
            ref: 'name',
            required:true,
          }          
        ],
        channelFields:[
          {
            label: 'Channel',
            getValue: item => item.channel,
            type: 'text',
            key:'channel',
            ref: 'channel',
            required:true,
          },
          {
            label: 'Value',
            getValue: item => item.value,
            type: 'text',
            key:'value',
            ref: 'value',
            required:true,
          },
          {
            label: 'Enabled',
            getValue: item => (item.isEnabled)? 'on':'off',
            getClass: item => (item.isEnabled) ? 'isEnabled' : 'isDisabled',
            type: 'text',
            key:'isEnabled',
            ref: 'isEnabled',
            required:true,
          }          
        ],
        selectedBill: null,
        temp:{},

        billSelected:false,
        editing: false,
        deleting: false,
        selected:null,
        loading: false,
        event:false,
       
        pageLoading:false,
        pageSizes:[10,20,50],
        initialPage: {
          size:10,
          index:1
        },
        page: {
          size:10,
          index:1
        },

        addBtnLabel:'Add New',

      }
    },
    mounted(){
      this.getBillAcceptors(this.initialPage);
      this.getCountris();
      this.getDeviceManufacturers();
      this.getEgmManufacturers();
    },
    watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
            }
        },
    },
    computed: {
      numberOfPages: function(){
        return Math.ceil(this.data.count/this.page.size)
      },
    },
    methods: {
      getBillAcceptors(item){
        axios.get('infrastructure/billAcceptors?pageIndex='+(item.index-1)+'&pageSize='+item.size)
            .then(res=>{
                let tempData = res.data;
                //console.log(tempData);
                tempData.data.forEach(el=>{
                  el.billAcceptorChannels = el.billAcceptorChannels.filter(channel=>channel.status !== 'Deleted');
                });
                this.data = tempData;

                if(this.billSelected){
                  let currentSelectedBill = this.data.data.filter(bill=>bill.id === this.selectedBill.id);
                  this.getBillChannels(currentSelectedBill[0]);
                  //console.log(currentSelectedBill[0]);
                }
            })
      },
      setSort(item){
        this.page.index = 1;
        this.page.size = item;
        this.getBootloaders(this.page);
      },
      getBillChannels(item){
        this.selectedBill = item;
        this.billSelected = true;
      },
      getCountris(){
        axios.get('countries')
          .then(res=>{
            this.countries = res.data;
          })
      },
      getDeviceManufacturers(){
        axios.get('deviceManufacturers?typeId=4')
          .then(res=>{
            this.deviceManufacturers = res.data;
          })
      },
      getEgmManufacturers(){
        axios.get('egmManufacturers')
          .then(res=>{
            this.egmManufacturers=res.data;
          })
      },
      validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

          return valid;
      },
      createBill(item){
        this.loading = true;
        if(!this.validate(this.billFields)) {
          Vue.toasted.error('Please fill in the form');
          this.loading = false;
          return; 
        }
        axios.post('billAcceptors', item)
              .then(res=> {
                  Vue.toasted.success('Item created.');
                  this.getBillAcceptors(this.initialPage);
                  this.closeModals();
                  this.loading = false;
              })
              .catch(err => {
                  
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error processing your request'); 
                  }

                  this.loading = false;

                });
      },
      createChannel(item){
        this.loading = true;
        item.billAcceptorId = this.selectedBill.id;
        if(!this.validate(this.channelFields)) {
          Vue.toasted.error('Please fill in the form');
          this.loading = false;
          return; 
        }
        axios.post('billAcceptorChannels', item)
              .then(res=> {
                  Vue.toasted.success('Item created.');
                  this.getBillAcceptors(this.initialPage);
                  this.closeModals();
                  
                  this.loading = false;
              })
              .catch(err => {
                  
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error processing your request'); 
                  }

                  this.loading = false;

                });
      },
      updateBill(item){
        this.loading = true;            
        if(!this.validate(this.billFields)) {
            Vue.toasted.error('Please fill in the form');
            this.loading = false;
            return; 
        }
        
        axios.put('billAcceptors/'+item.id, item)
              .then(res => {

                Vue.toasted.success('Item updated.');
                this.getBillAcceptors(this.initialPage);
                this.closeModals();
                                        
                this.loading = false;
                
              })
              .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

              });
      },
      updateChannel(item){
        this.loading = true;            
        if(!this.validate(this.channelFields)) {
            Vue.toasted.error('Please fill in the form');
            this.loading = false;
            return; 
        }
        
        axios.put('billAcceptorChannels/'+item.id, item)
              .then(res => {

                Vue.toasted.success('Item updated.');
                this.getBillAcceptors(this.initialPage);
                this.closeModals();
                
                this.loading = false;
                
              })
              .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

              });
      },
      deleteBill(item){
        axios.delete('billAcceptors/' + item.id)
              .then(res => {
                  Vue.toasted.success('Item deleted.');
                  this.closeModals();
                  this.getBillAcceptors(this.initialPage);
              })
              .catch(err => {
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error deleting this item.')
                  }
              });
      },
      deleteChannel(item){
        axios.delete('billAcceptorChannels/' + item.id)
              .then(res => {
                  Vue.toasted.success('Item deleted.');
                  this.closeModals();
                  this.getBillAcceptors(this.initialPage);
              })
              .catch(err => {
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error deleting this item.')
                  }
              });
      },

      openAddBill(){
        this.editing = true;      
        this.event = true;     
      },
      openAddChannel(){
        this.editing = true; 
        this.event = false;
        this.temp.isEnabled = false;
      },
      openEditBillModal(item){ 
        this.editing = true;
        this.event = true;
        let obj = JSON.parse(JSON.stringify(item));

        this.temp = {
          id: obj.id,
          name: obj.name,
          countryId: obj.countryId,
          manufacturerId:obj.manufacturerId,
          egmManufacturerId: obj.egmManufacturerId,
          billAcceptorChannels: obj.billAcceptorChannels
        }
      },
      openEditChannelModal(item){ 
        this.editing = true;
        this.event = false;
        this.temp = JSON.parse(JSON.stringify(item));
      },
      openDeleteModal(selected){
        this.selected = selected;
        this.deleting = true;
      },
      closeModals() {
        this.temp = {};
        this.selected = null;
        this.editing = false;
        this.deleting = false;
      },
    }
}
</script>

<template>
    <div class="tab games">
       <div style="margin-top:20px">
			<v-row>
                <v-col cols='12'>
                    <div class="">
                            <v-btn :elevation="0" class="under_nav_btn button_purple float-left" @click="openAddNewModal">Add New<v-icon>mdi-plus</v-icon></v-btn>
                            <v-btn :elevation="0" class="under_nav_btn button_purple float-left">Sort by<v-icon>mdi-menu-down</v-icon></v-btn>
                        </div>
                </v-col>
            </v-row>
		</div>
        <div>
			<v-row v-if="data">
                <v-col xs='12' sm='12' md='6' lg="4" v-for="item in data" :key="item.id">
                    <div class="card-purple card-border-radius" v-if="data" data-aos="fade">                            
                        <div class="card_image_header">
                            <v-row no-gutters>
                                <v-col cols='10'>
                                    <p>{{item.name}}</p>
                                    <p>Version: {{item.version}}</p>
                                </v-col>
                                <v-col cols='2'>
                                    <a class="float-right" @click="openDeleteModal(item)">
                                        <v-icon color="gray">mdi-delete-outline</v-icon>
                                    </a>
                                     <a class="float-right" @click="openEditModal(item)">
                                        <v-icon color="gray">mdi-pencil-outline</v-icon>
                                    </a> 
                                </v-col>
                            </v-row>
                            
                        </div>
                        <div class="game_img">
                            <v-img 
                                height='240'
                                :src='item.coverMedia ? getImage(item.coverMedia.fileName) : "/img/images_placeholder2x.png"'
                            />
                        </div>
                        <div class="game_info">
                            <v-row>
                                <v-col cols='12'>
                                    <div v-if='item.gameDescriptors.length!==0'>
                                        <span  v-for='gameDesc in item.gameDescriptors' :key='gameDesc.id'>
                                             <span :class="[gameDesc.gameDescriptorTypeId===3 ? 'lines' : 'reels']">{{gameDesc.gameDescriptorValue.name}}</span>
                                        </span>
                                    </div>
                                    <div v-if='item.gameDescriptors.length===0'>
                                         <span class="game_info_label">Descriptors</span>
                                    </div>
                                   
                                </v-col>
                               
                                <v-col cols='12' v-if='item.gameRTPs'>
                                    <div v-if='item.gameRTPs.length!==0'>
                                        <span class='mix_rtp' v-for='rtp in item.gameRTPs' :key='rtp.id'>{{rtp.value}}</span>
                                    </div>
                                    <div v-if='item.gameRTPs.length===0'>
                                         <span class="game_info_label">RTP% Options</span>
                                    </div>
                                    
                                </v-col>

                                <span class='game_info_divider'>
                                    <span class='label'>Game Mixes <span v-if='item.gameMixes'>- {{item.gameMixes.length}}</span></span>
                                </span>

                                <v-col cols='12' class='game_mixes_container' v-if='item.gameMixes'>
                                    <span class="game_mixes" v-for='mix in item.gameMixes' :key='mix.id'> {{mix.name}}</span>
                                </v-col>
                            </v-row>
                        </div>
                        
                    </div>
                </v-col>
            </v-row>	
		</div>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="800">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Game</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                        <span class="modal_step">Step {{step}}/{{noOfSteps}}</span>   
                    </div>
                    <step-pos :step='step' :noOfSteps='noOfSteps'></step-pos>
                    <v-card-text >

                        <!-- Add New Game -->
                        
                        <v-row justify='center' v-show='!temp.id && step===1'> 
                            <v-col cols='6' >
                                <br>
                                <form>
                                    <div class="fields">
                                        <div class="field">
                                            <v-text-field
                                            label='Name'
                                            v-model="temp.name"
                                            :rules="[() => !!temp.name || 'This field is required']"
                                            ref='gameName'
                                            outlined
                                            />
                                            <v-text-field
                                            label='Version'
                                            v-model="temp.version"
                                            :rules="[() => !!temp.version || 'This field is required']"
                                            ref='gameVersion'
                                            outlined
                                            />
                                            <div class="game_add_rtps">
                                                <span class='label'>RTP% Options</span>
                                                <div class='game_add_rtps_padd'>
                                                    <v-text-field
                                                    label='RTP%'
                                                    append-icon='mdi-plus-circle-outline'
                                                    @click:append="addRTP(tempRTP)"
                                                    ref='gameRTPs'
                                                    v-model.number='tempRTP'
                                                    type="number"
                                                    outlined
                                                    >
                                                    </v-text-field>
                                                    <div>
                                                        <span class='current_rtps' v-for='(item,index) in rtpArr' :key='index'>
                                                            <span class='game_add_rtp'>{{item.value}}</span> <v-icon @click='removeRTP(index)'>mdi-close</v-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                             <v-col cols='6' >
                                <br>
                                <form>
                                    <div class="game_descriptors">
                                        <span class='label'>Descriptors</span>
                                        <div class='game_desc_pad'>
                                            <game-descriptor 
                                            @descriptorTypeValues='addDescTypeValues($event,item)' 
                                            v-for='item in gameDescriptorTypes' 
                                            :key='item.id' 
                                            :gameType='item'
                                            :isEdited='false'></game-descriptor>
                                        </div>
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                        <v-row  v-show='!temp.id && step === noOfSteps' >
                            <v-col v-for="(item,index) in gameImages" :key='index' cols='6'>
                                <div class="game_cover_border">
                                    <span class='label'>{{item.label}}</span>
                                    <div class="game_image_input" @click='$refs.fileInput[index].click()'>
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, index)'>
                                        <img
                                        height='170'
                                        contain
                                        :src='item.tempImg ? item.tempImg : "/img/images_placeholder2x.png"'
                                        />
                                    </div>
                                    <div class="game_image_overlay">
                                        <v-btn class='remove_preview' :elevation='0' @click='removePreview(index)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                            </v-col>                            
                        </v-row>
                        
                        <!-- Edit Game -->
                        
                        <v-row justify='center' v-if='temp.id && step===1'> 
                           
                                <v-col cols='6' >
                                <br>
                                <form>
                                    <div class="fields">
                                        <div class="field">
                                            <v-text-field
                                            label='Name'
                                            v-model="temp.name"
                                            :rules="[() => !!temp.name || 'This field is required']"
                                            ref='gameName'
                                            outlined
                                            />
                                            <v-text-field
                                            label='Version'
                                            v-model="temp.version"
                                            :rules="[() => !!temp.version || 'This field is required']"
                                            ref='gameVersion'
                                            outlined
                                            />
                                            <div class="game_add_rtps">
                                                <span class='label'>RTP% Options</span>
                                                <div class='game_add_rtps_padd'>
                                                    <v-text-field
                                                    label='RTP%'
                                                    append-icon='mdi-plus-circle-outline'
                                                    @click:append="addRTP(tempRTP)"
                                                    ref='gameRTPs'
                                                    v-model.number='tempRTP'
                                                    type="number"
                                                    min='1'
                                                    outlined
                                                    >
                                                    </v-text-field>
                                                    <div>
                                                        <span class='current_rtps' v-for='(item,index) in rtpArr' :key='index'>
                                                            <span v-if='item.status!=="Deleted"' class='game_add_rtp'>{{item.value}}<v-icon @click='updateRTP(item,index)'>mdi-close</v-icon></span> 
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                             <v-col cols='6' >
                                <br>
                                <form>
                                    <div class="game_descriptors">
                                        <span class='label'>Descriptors</span>
                                        <div class='game_desc_pad'>
                                            <game-descriptor 
                                                @descriptorTypeValues='addDescTypeValues($event,item)' 
                                                v-for='item in gameDescriptorTypes' 
                                                :key='item.id' 
                                                :gameType='item'
                                                :isEdited='editing'
                                                :activeDesc='activeEditDesc'
                                            />
                                        </div>
                                    </div>
                                </form>
                            </v-col>
                            
                        </v-row>
                        <v-row  v-if='temp.id && step === noOfSteps'>
                            <v-col v-for="(item,index) in gameImages" :key='index' cols='6'>
                                <div class="game_cover_border">
                                    <span class='label'>{{item.label}}</span>
                                    <div class="game_image_input" @click='$refs.fileInput[index].click()'>
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, index)'>

                                        <img
                                        v-if='editImages[index].tempImg'
                                        height='170'
                                        contain
                                        :src='item.tempImg ?  "/img/images_placeholder2x.png" : getImage(editImages[index].tempImg)'
                                        />
                                         <img
                                        v-if='!editImages[index].tempImg'
                                        height='170'
                                        contain
                                        :src='item.tempImg ? item.tempImg : "/img/images_placeholder2x.png"'
                                        />
                                    </div>
                                    <div class="game_image_overlay">
                                        <v-btn class='remove_preview' :elevation='0' @click='removePreview(index)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>

                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        
                        <!-- Buttons for Add New Modal -->
                        
                        <v-btn class="modal_btn"  v-if="!temp.id && step<noOfSteps" :elevation="0" color="green" dark @click="createGame(temp)">
                            Next
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="!temp.id && step===noOfSteps" :elevation="0" color="green" dark @click="addImagesToGames(createdGame, gameImages, false)">
                            Save 
                        </v-btn>
                       
                        
                        <!-- Buttons for Edit Modal -->
                        <v-btn class="modal_btn"  v-if="temp.id && step<noOfSteps" :elevation="0" color="green" dark @click="updateGame(temp, activeEditDesc, tempDesc)">
                            Next
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && step===noOfSteps" :elevation="0" color="green" dark @click="addImagesToGames(temp, gameImages, false)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Game</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div> 
</template>
<script>
import StepProgress from '@/components/StepProgress.vue'
import GameDescriptor from '@/components/GameDescriptor.vue'
export default {
    components: {
        'step-pos' : StepProgress,
        'game-descriptor' : GameDescriptor
    },
    data(){
        return {
            data:null,
            gameDescriptorTypes:null,
            gameDescriptorValues:null,            

            step: 1, 
            noOfSteps: 2,

            editing: false,
            deleting: false,
            selected: null,
            loading: false,

			temp:{},
            tempDesc:{},
            tempRTP:null,
            activeEditDesc:{},

            rtpArr:[],
            gameFields:[
                {
                    label:'Name',
                    ref:'gameName',
                    required:true
                },
                {
                    label:'Version',
                    ref:'gameVersion',
                    required:true
                }
            ],

            gameImages:[
                {   
                    label:'Game Cover',
                    tempImg: null,
                    File: null,
                    height: null,
                    width: null,
                },
                {   
                    label: 'Game Symbols',
                    tempImg: null,
                    File: null,
                    height: null,
                    width: null,
                }
            ],

            editImages:[
                {   
                    label:'Game Cover',
                    tempImg: null,
                },
                {   
                    label: 'Game Symbols',
                    tempImg: null,
                }
            ],

            createdGame:null
        }
    },
    mounted(){
        this.getGamesByManufacturer();
        this.getGameDescriptorTypes();
        this.getGameDescriptorValues();
    },
     watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
                this.selected = null;
                this.editing = false;
                this.deleting = false;
                this.step = 1;
                this.rtpArr = [];
                this.tempDesc = {};
                this.activeEditDesc = {};
                this.gameImages = [
                {   
                    label:'Game Cover',
                    tempImg: null,
                    File: null,
                    height: null,
                    width: null,
                },
                {   
                    label: 'Game Symbols',
                    tempImg: null,
                    File: null,
                    height: null,
                    width: null,
                }
                ],
                this.editImages = [
                {   
                    label:'Game Cover',
                    tempImg: null,
                },
                {   
                    label: 'Game Symbols',
                    tempImg: null,
                }
                ]
            }
        },
    },
    methods:{
        getGamesByManufacturer(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id+'/games')
                .then(res => {
                    this.data = res.data;
                });
        },

        getGameDescriptorTypes(){
            axios.get('gameDescriptorTypes')
                .then(res => {
                    this.gameDescriptorTypes = res.data;
                });
        },

        getGameDescriptorValues(){
            axios.get('gameDescriptorValues')
                .then(res => {
                    this.gameDescriptorValues = res.data;
                });
        },

        addRTP(item){
            if(item < 0 || item === '' || item===null){
                this.tempRTP = '';
                Vue.toasted.error('Invalid RTP');
                return;
            }
            else if (this.rtpArr.filter(e=>e.value===item&&e.status!=='Deleted').length>0){
                Vue.toasted.error('RTP already added');
                this.tempRTP = '';
                return;
            }
            let x = item;                        
            let rtp = {};
            rtp = { 
                    'value': x,
                    'status':'Active'
                    };
            this.rtpArr.push(rtp);

            this.tempRTP = '';
        },

        removeRTP(index){
            let x = this.rtpArr.filter(obj=>{
                return this.rtpArr.indexOf(obj) !== index;
            });
            this.rtpArr = x;
        },

        updateRTP(item,index){
            console.log(item.id,index);

            if(item.id){
                item.status='Deleted';
            }
            else if(!item.id){
                 let x = this.rtpArr.filter(obj=>{
                    return this.rtpArr.indexOf(obj) !== index 
                });
                this.rtpArr = x;
            }
        },

        addDescTypeValues(event, item){

            this.tempDesc[item.id] = event;

        },

        onFileChange(e, index){
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length){
                return;
            }

            this.gameImages[index].File = files[0];
           
            this.createImage(files[0], index);
        },

        createImage(file, index){

            var reader = new FileReader();
            var self = this;

            reader.readAsDataURL(file);
            reader.onload = function(e) {
                let image = new Image();

             
                image.src= e.target.result;
                image.onload = function () {
                    self.gameImages[index].height = this.height;
                    self.gameImages[index].width = this.width;      
                }
                self.gameImages[index].tempImg = e.target.result;
               
                
                self.$forceUpdate();
            };

            this.$refs.fileInput[index].value = '';
            
        },

        removePreview(index){
            if(!this.editImages[index].tempImg){
                this.gameImages[index].File = null;
                this.gameImages[index].tempImg = null;
                this.gameImages[index].height = null;
                this.gameImages[index].width = null;
            }
            else {
                this.loading = true;

                var formData = new FormData();
                if(index === 0){
                    formData.append('DeleteCover', 'true');
                    this.editImages[index].tempImg = null;
                }
                else {
                    formData.append('DeleteSymbols', 'true');
                    this.editImages[index].tempImg = null;
                }



                axios.post('games/'+this.temp.id+'/media', formData, {
                    headers: {
                        'Content-Type' : 'multipart/form-data'
                    }
                })
                .then(res=>{
                    Vue.toasted.success('Image Deleted.');
                    this.getGamesByManufacturer();
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });
            }
            


            this.$forceUpdate();
        },

        validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        openAddNewModal(){
            this.editing = true;
            this.step = 1;
            this.availableGames = this.games;
           
        },

        openEditModal(item){
            this.editing = true;
            this.step = 1;

            this.selected =  JSON.parse(JSON.stringify(item));
           
            this.rtpArr = Object.assign([], this.selected.gameRTPs);
            
            let x = [...this.selected.gameDescriptors];

            this.activeEditDesc = x.map(obj=>{
                let nObj = {}
                nObj[obj.gameDescriptorTypeId]= {
                    gameDescriptorTypeId:obj.gameDescriptorTypeId,
                    gameDescriptorValueId:obj.gameDescriptorValueId,
                    id:obj.id,
                    gameId:obj.gameId,
                    status:obj.status
                }
                return nObj;
            })

            this.temp = {
                id: this.selected.id,
                name: this.selected.name,
                version: this.selected.version,
                status: this.selected.status,
                egmManufacturerId: this.selected.egmManufacturerId,
                gameMixes: this.selected.gameMixes
            };

            if(this.selected.coverMedia){
                this.editImages[0].tempImg = this.selected.coverMedia.fileName;
            }
            else {
                this.editImages[0].tempImg = null;
            }

            if(this.selected.symbolsMedia){
                this.editImages[1].tempImg = this.selected.symbolsMedia.fileName;
            }
            else {
                this.editImages[1].tempImg = null;
            }

           
           
        },

        openDeleteModal(selected){
            this.selected = selected;
            this.deleting = true;
        },

        closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
            this.step = 1;
            this.rtpArr = [];
            this.tempDesc = {};
            this.activeEditDesc = {};
            this.gameImages=[
                {   
                    label:'Game Cover',
                    tempImg: null,
                    File: null,
                    height: null,
                    width: null,
                },
                {   
                    label: 'Game Symbols',
                    tempImg: null,
                    File: null,
                    height: null,
                    width: null,
                }
            ],
            this.editImages=[
                {   
                    label:'Game Cover',
                    tempImg: null,
                },
                {   
                    label: 'Game Symbols',
                    tempImg: null,
                }
            ]
        },

        createGame(item){
            this.loading = true;
            item.egmManufacturerId = this.$route.params.manufacturer_id;
            item.gameRTps = this.rtpArr;

            let gameDescriptors=[];
            for(const key in this.tempDesc){
                if(this.tempDesc.hasOwnProperty(key)){
                    gameDescriptors.push(...this.tempDesc[key]);
                }
            }

            item.gameDescriptors = gameDescriptors;


            if(!this.validate(this.gameFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }

          

            axios.post('games', item)
            .then(res => {
                this.createdGame = res.data;
                Vue.toasted.success('Game created.');
                this.getGamesByManufacturer();

                this.temp={};
                this.loading = false;
                this.$emit('refreshStats');
            })
            .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

                });

            this.step+=1;
        },

        updateGame(temp, activeEditDesc, tempDesc){
            let x = [];
            for(const key in tempDesc){
                if(tempDesc.hasOwnProperty(key)){
                    x.push(...tempDesc[key]);
                }
            }
           //console.log(x);

            x.forEach(el=>{
                activeEditDesc.forEach(item=>{
                    for(const key in item){
                        if(item.hasOwnProperty(key)){
                                if(key == el.gameDescriptorTypeId && item[key].gameDescriptorValueId == el.gameDescriptorValueId){
                                
                                    item[key].status = 'Deleted'                                 
                                    
                                }
                        }
                    }
                });
            });

            let y = [];
            activeEditDesc.forEach(item=>{
                 for(const key in item){
                    if(item.hasOwnProperty(key)){
                        y.push(item[key]);
                    }
                }
            });
           
           //console.log(y);

            let t = x.filter(item => {
                return y.every(el => {
                    return item.gameDescriptorTypeId != el.gameDescriptorTypeId || item.gameDescriptorValueId != el.gameDescriptorValueId
                });
            });
           
            //console.log(t);

            let gameDescriptors = y.concat(t);
            //console.log(gameDescriptors);

            temp.gameRTPs = this.rtpArr;
            temp.gameDescriptors = gameDescriptors;
            this.loading = true;
            
            axios.put('games/'+temp.id, temp)
                .then(res=> {
                    Vue.toasted.success('Game updated.');
                    this.getGamesByManufacturer();
                                       
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });
            this.step+=1;
        },

        addImagesToGames(game,media,keepOpen){
            this.loading = true;
            var formData = new FormData();

           
            media.forEach(el =>{
                for(const key in el){
                    if(el.hasOwnProperty(key)){
                        let value = el[key];
                        if(el[key]!==null){
                            formData.append(key+el.label.split(' ')[1], value)
                        } 
                    }
                }
            });
            formData.delete('tempImgCover');
            formData.delete('tempImgSymbols');
            
            axios.post('games/'+game.id+'/media', formData, {
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })
                .then(res=>{
                    console.log(res.data);
                    Vue.toasted.success('Images created.');
                    this.getGamesByManufacturer();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });
        },

        deleteItem(item) {
            //console.log(item);
            axios.delete('games/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getGamesByManufacturer();
                    this.$emit('refreshStats');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });
        },
    
    }

}
</script>
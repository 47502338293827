<template>
    <div class="cabinet details">
        <div style='margin-top:20px'>
			<v-row>
                <v-col xs='12' sm='12' md='12' lg="8">
                    <div class="card-purple card-border-radius" v-if="cabinet" data-aos="fade">                            
                        <v-row style='padding:20px; background-color:white' no-gutters >
                            <v-col cols='3'>
                                <v-btn-toggle
                                    v-model='activeImageInput'
                                    group
                                    color='blue'
                                >
                                    <v-btn class="cabinet_image_btn">L</v-btn>
                                    <v-btn class="cabinet_image_btn">F</v-btn>
                                    <v-btn class="cabinet_image_btn">R</v-btn>
                                </v-btn-toggle>
                                <div v-if="activeImageInput === 0">
                                    <v-img
                                        height="300" 
                                        contain
                                        src='/img/right@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>30&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                                <div v-if="activeImageInput === 1">
                                    <v-img 
                                        height="300"
                                        contain
                                        src='/img/front@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>90&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                                <div v-if="activeImageInput === 2">
                                    <v-img 
                                        height="300"
                                        contain
                                        src='/img/left@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>150&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                                <v-btn style='margin-top:50px' class="modal_btn"  :elevation="0" color="green" dark @click="updateCabinetImages(temp,tempMedia, false)">
                                    Save
                                </v-btn>
                            </v-col>
                            <v-col cols='9'>
                                <div v-if='activeImageInput === 0' class="image_input_container">
                                    <div  class="image_border_background" @click='$refs.fileInput.click()' >
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        v-if='this.cabinet[0].frontLeftMedia'
                                        class="img_preview"
                                        height="450"
                                        contain
                                        :src='tempImg.leftImg ? tempImg.leftImg : getImage(this.cabinet[0].frontLeftMedia.fileName)'
                                        />
                                        <img
                                        v-if='!this.cabinet[0].frontLeftMedia'
                                        :class="[tempImg.leftImg ? 'img_preview' : '']"
                                        height="450"
                                        contain
                                        :src='tempImg.leftImg'
                                        />
                                    </div>
                                    <div  v-if='this.cabinet[0].frontLeftMedia' class="image_overlay">
                                        <v-btn :elevation='0' class="float-right" @click='removeImage(temp, activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                        
                                    <div v-if='tempImg.leftImg' class="image_overlay">
                                         <v-btn :elevation='0' class="float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                                
                                <div v-if='activeImageInput === 1' class="image_input_container">
                                    <div  class="image_border_background" @click='$refs.fileInput.click()' >
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        v-if='this.cabinet[0].frontMedia'
                                        class="img_preview"
                                        height="450"
                                        contain
                                        :src='tempImg.frontImg ? tempImg.frontImg : getImage(this.cabinet[0].frontMedia.fileName)'
                                        />
                                        <img
                                        v-if='!this.cabinet[0].frontMedia'
                                        :class="[tempImg.frontImg ? 'img_preview' : '']"
                                        height="450"
                                        contain
                                        :src='tempImg.frontImg'
                                        />
                                    </div>
                                    <div  v-if='this.cabinet[0].frontMedia' class="image_overlay">
                                        <v-btn :elevation='0' class="float-right" @click='removeImage(temp, activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                        
                                    <div v-if='tempImg.frontImg' class="image_overlay">
                                         <v-btn :elevation='0' class="float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>

                                <div v-if='activeImageInput === 2' class="image_input_container">
                                    <div  class="image_border_background" @click='$refs.fileInput.click()' >
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        v-if='this.cabinet[0].frontRightMedia'
                                        class="img_preview"
                                        height="450"
                                        contain
                                        :src='tempImg.rightImg ? tempImg.rightImg : getImage(this.cabinet[0].frontRightMedia.fileName)'
                                        />
                                        <img
                                        v-if='!this.cabinet[0].frontRightMedia'
                                        :class="[tempImg.rightImg ? 'img_preview' : '']"
                                        height="450"
                                        contain
                                        :src='tempImg.rightImg'
                                        />
                                    </div>
                                    <div  v-if='this.cabinet[0].frontRightMedia' class="image_overlay">
                                        <v-btn :elevation='0' class="float-right" @click='removeImage(temp, activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                        
                                    <div v-if='tempImg.rightImg' class="image_overlay">
                                         <v-btn :elevation='0' class="float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                            </v-col>
                            
                        </v-row>                        
                    </div>
                </v-col>
            </v-row>	
		</div>
    </div>
</template>
<script>
export default {

    data() {
       return {
            cabinet:null,
            temp: null,
            activeImageInput: 0,

            loading:false,
            editing: false,
            deleting: false,
           
            tempImg: {},
            tempMedia: {},
            mediaDim:{},
       } 
    },
    mounted(){
        this.getCabinetsByManufacturer();
    },
    
    methods: {

        getCabinetsByManufacturer(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id+'/cabinets')
                .then(res=>{
                    this.cabinet = res.data.filter(item => item.id == this.$route.params.cabinet_id);
                    this.setTemp();
                })
        },

        setTemp(){
            this.temp = JSON.parse(JSON.stringify(...this.cabinet));
        },

        onFileChange(e, position){
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length){
                return;
            }
            if(position===0){
                this.tempMedia.Left = files[0];
            }
            else if(position===1){
                this.tempMedia.Front = files[0];
            }
            else{
                this.tempMedia.Right = files[0];
            }
           
            this.createImage(files[0], position);
        },

        createImage(file, position){

            var reader = new FileReader();
            var self = this;

            reader.readAsDataURL(file);
            reader.onload = function(e) {
                let image = new Image();

                if(position === 0) {
                    image.src= e.target.result;
                    image.onload = function () {
                        self.mediaDim['HeightLeft'] = this.height;
                        self.mediaDim['WidthLeft'] = this.width;      
                    }
                    self.tempImg.leftImg = e.target.result;
                }

                else if(position === 1) {
                    image.src= e.target.result;
                    image.onload = function () {
                        self.mediaDim['HeightFront'] = this.height;
                        self.mediaDim['WidthFront'] = this.width;
                          
                    }
                    self.tempImg.frontImg = e.target.result;
                }

                else {
                    image.src= e.target.result;
                    image.onload = function () {
                        self.mediaDim['HeightRight'] = this.height;
                        self.mediaDim['WidthRight'] = this.width;    
                    }
                    self.tempImg.rightImg = e.target.result;
                }
                
                self.$forceUpdate();
            };

            this.$refs.fileInput.value = '';
            
        },

        removePreview(position){
            if(position===0){
                this.tempMedia.Left = null;
                this.tempImg.leftImg = null;
            }
            else if(position===1){
                this.tempMedia.Front = null;
                this.tempImg.frontImg = null;
            }
            else{
                this.tempMedia.Right = null;
                this.tempImg.rightImg = null;
            }
            this.$forceUpdate();
        },

        validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        openAddNewModal(){
            this.editing = true;
            this.step = 1;
           
        },

        openEditModal(item){
            this.editing = true;
            this.selected = Object.assign({}, item);
            let platforms = this.selected.platforms.map(obj => {
                let nObj = obj.egmPlatform.id;
                return nObj;
            });
            
            platforms.sort((a,b)=>{
                return a-b;
            })

            this.temp = {
                id:this.selected.id,
                EGMManufacturerId: this.selected.egmManufacturerId,
                egmButtonLayoutId: this.selected.egmButtonLayoutId,
                Name: this.selected.name,
                Model: this.selected.model,
                CabinetTypeId: this.selected.cabinetType.id,
                Platforms: platforms,
                Status: this.selected.status
            }

            // this.temp = Object.assign({}, item);
            // this.temp.cabinetTypeId = this.selected.cabinetType.id;
            // this.temp.platforms = platforms;

            this.step = 1;
        },

        openDeleteModal(selected){
            this.selected = selected;
            this.deleting = true;
        },

        closeModals() {
            this.temp = {};
            this.tempMedia = {};
            this.mediaDim = {};
            this.tempImg =  {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
            this.step = null;
        },

        updateCabinet(selected, item){
            this.loading = true;
            let originalValues = [];
            selected.platforms.forEach(el=>{
                originalValues.push(el.egmPlatformId);
            });
                        
            let platforms = item.Platforms.map(obj => {
                let nObj = {}
                nObj['EGMModelId'] = item.id;
                nObj['egmPlatformId'] = obj;
                nObj['Status'] = 'Active';
                return nObj;
            });
            
            selected.platforms.forEach(el=>{
                platforms.forEach(item => {
                    if(el.egmPlatformId === item.egmPlatformId){
                        item.Id = el.id;
                    }
                });
            });

            originalValues.forEach(elem=>{
                if(!item.Platforms.includes(elem)){
                    
                    let deletedPlatforms = selected.platforms.find(el =>el.egmPlatformId === elem);
                    deletedPlatforms.status =  'Deleted';
                    //delete deletedPlatforms.egmPlatform;
                    platforms.push(deletedPlatforms);
                   
                }
            });

            platforms.sort((a,b)=>{
                return a.egmPlatformId - b.egmPlatformId;
            });

            item.Platforms = platforms;



            if(!this.validate(this.cabFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }

            this.step+=1;
            
            console.log(item);

            axios.put('egmModels/'+item.id, item)
            .then(res => {
                Vue.toasted.success('Cabinet updated.');
                this.getCabinetsByManufacturer();

                // this.temp={};
                this.loading = false;
            })
            .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

                });
        },

        updateCabinetImages(cabinet,media,keepOpen){
           
            this.loading = true;
            var formData = new FormData();

            //Setting image dimensions 
            //before passing object to API
            

            for(const key in media){
                if(media.hasOwnProperty(key)){
                    formData.append('File'+key, media[key])
                }
            }
           
            for(const key in this.mediaDim){
                if(this.mediaDim.hasOwnProperty(key)){
                    let value = this.mediaDim[key];
                    formData.append(key, value);
                }
            }


            axios.post('egmModels/'+cabinet.id+'/media', formData, {
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })
                .then(res=>{
                    console.log(res.data);
                    Vue.toasted.success('Item created.');
                    this.getCabinetsByManufacturer();
                    
                    if(!keepOpen){
                        this.closeModals();                        
                    }
                    this.setTemp();

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });

        },

        removeImage(cabinet, position){
            this.loading = true;
            let image ;
            var deleteImage = new FormData();
            if(position===0){
                image = 'DeleteLeft';
                this.temp.frontLeftMedia = null;
            }
            else if(position===1){
                image = 'DeleteFront' ;
                this.temp.frontMedia = null;
            }
            else {
                image = 'DeleteRight' ;
                this.temp.frontRightMedia = null;
            }

            deleteImage.append(image, 'true');

            axios.post('egmModels/'+cabinet.id+'/media', deleteImage)
                .then(res=>{
                    console.log(res.data);
                    Vue.toasted.success('Image deleted.');
                    this.getCabinetsByManufacturer();
                                       
                    
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });
        },

        deleteItem(item) {
            console.log(item);
            axios.delete('egmModels/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getCabinetsByManufacturer();
                    this.$emit('refreshStats');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });
        },
    }
}
</script>
<template>
  <div class="tab bootloader" >
        <v-container>
            <v-row>
                <v-col xs='12' sm='12' md='12' lg='12' xl='12'>
                    <div class="card card-blue" v-if="data" data-aos="fade">
                            <div class="card_header">
                            <span class="float-left">Bootloader</span>
                            <span class="float-right">
                                <add-new-item
                                 :label='addBtnLabel'
                                @openSpecificCanAdd='openAddNewModal'
                                />
                            </span>
                            <div class='pageSizeMenu_container float-right'>
                                <v-menu content-class="pageMenu" offset-y left max-width='190' data-aos="fade">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="white" fab x-small dark v-bind="attrs" v-on="on" :elevation='0'>
                                            <span class="sort">{{page.size}}</span>
                                            <v-icon class="menu_icon">mdi-menu-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in pageSizes" :key="index" v-ripple @click="setSort(item)">
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <div class='float-right'>Items per page:</div>
                        </div>
                        <crud-table class="gen_table" :data='data.data' :fields='fields' :canEdit="true" :canDelete="true" @edit="openEditModal" @delete="openDeleteModal"></crud-table>  
                        <div class="text-center table-pagination">
                            <v-pagination
                            v-model="page.index"
                            :length="numberOfPages"
                            :total-visible="6"
                            color='#1976d2'
                            @input='getBootloaders(page)'
                            ></v-pagination>
                        </div>     
                    </div>  
                </v-col>
            </v-row>
        </v-container>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Bootloader</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field" v-if='!temp.id'>
                                  <v-row>
                                    <v-col cols='6'>
                                      <v-text-field 
                                        label="Version"
                                        v-model="temp.version"
                                        :rules="[() => !!temp.version || 'This field is required']"
                                        ref='version'
                                        outlined
                                      />
                                      <v-text-field 
                                          label="Date"
                                          v-model="temp.date"
                                          :rules="[() => !!temp.date || 'This field is required']"
                                          ref='date'
                                          outlined
                                          @click='showPicker = !showPicker'
                                      />
                                      <v-text-field 
                                          label="Target"
                                          v-model="temp.target"
                                          :rules="[() => !!temp.target || 'This field is required']"
                                          ref='target'
                                          outlined
                                      />
                                      <v-text-field 
                                          label="Target Info"
                                          v-model="temp.targetInfo"
                                          :rules="[() => !!temp.targetInfo|| 'This field is required']"
                                          ref='targetInfo'
                                          outlined
                                      />
                                      <v-text-field 
                                          label="Description"
                                          v-model="temp.description"
                                          :rules="[() => !!temp.description || 'This field is required']"
                                          ref='description'
                                          outlined
                                      />
                                      <v-checkbox 
                                        label="Default" 
                                        v-model="temp.isDefault"
                                        :rules="[() => !!temp.isDefault || 'This field is required']"
                                        ref='isDefault'
                                        style='margin-bottom:8px; margin-top: 0px'
                                        />
                                      <v-text-field 
                                          label="Info"
                                          v-model="temp.info"
                                          :rules="[() => !!temp.info || 'This field is required']"
                                          ref='info'
                                          outlined
                                      />
                                    </v-col>
                                    <v-col cols='6'>
                                       <v-file-input
                                        label="File input"
                                        v-model="temp.file"
                                        :rules="[() => !!temp.file || 'This field is required']"
                                        ref='fileInput'
                                        messages='Allowed file extension .bin'
                                        outlined
                                        show-size
                                      ></v-file-input>
                                    </v-col>
                                    <div v-if='showPicker' style='position:absolute; top:145px;'>
                                      <v-date-picker
                                        v-model='temp.date'
                                        elevation='10'
                                        @input='showPicker = !showPicker'
                                      ></v-date-picker>
                                    </div>
                                  </v-row>
                                </div> 
                                <div class="field" v-if='temp.id'>
                                   
                                    <v-text-field 
                                        label="Version"
                                        v-model="temp.version"
                                        :rules="[() => !!temp.version || 'This field is required']"
                                        ref='version'
                                        outlined
                                      />
                                      <v-text-field 
                                          label="Date"
                                          v-model="temp.date"
                                          :rules="[() => !!temp.date || 'This field is required']"
                                          ref='date'
                                          outlined
                                          @click='showPicker = !showPicker'
                                      />
                                      <v-text-field 
                                          label="Target"
                                          v-model="temp.target"
                                          :rules="[() => !!temp.target || 'This field is required']"
                                          ref='target'
                                          outlined
                                      />
                                      <v-text-field 
                                          label="Target Info"
                                          v-model="temp.targetInfo"
                                          :rules="[() => !!temp.targetInfo|| 'This field is required']"
                                          ref='targetInfo'
                                          outlined
                                      />
                                      <v-text-field 
                                          label="Description"
                                          v-model="temp.description"
                                          :rules="[() => !!temp.description || 'This field is required']"
                                          ref='description'
                                          outlined
                                      />
                                      <v-checkbox 
                                        label="Default" 
                                        v-model="temp.isDefault"
                                        ref='isDefault'
                                        style='margin-bottom:8px; margin-top: 0px'
                                        />
                                      <v-text-field 
                                          label="Info"
                                          v-model="temp.info"
                                          :rules="[() => !!temp.info || 'This field is required']"
                                          ref='info'
                                          outlined
                                      />
                                      <v-text-field 
                                          value='1'
                                          ref='fileInput'
                                          style='display:none'
                                      />
                                      <div v-if='showPicker' style='position:absolute; top:145px;'>
                                        <v-date-picker
                                          v-model='temp.date'
                                          elevation='10'
                                          @input='showPicker = !showPicker'
                                        ></v-date-picker>
                                      </div>
                                </div> 
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="!temp.id" :elevation="0" color="green" dark @click="createItem(temp)">
                           Add New
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>URL</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>                  
    </div>
</template>

<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
  components: {
        'add-new-item': AddNewItem,
        'crud-table':CrudTable,
    },
    data(){
        return {
            data:null, 
            editing: false,
            deleting: false,
            selected: null,
            loading: false,
			temp:{},
            fields:[
                {
                    label: 'Version',
                    getValue: item => item.version,
                    type: 'text',
                    key:'version',
                    ref: 'version',
                    required:true,
                },
                {
                    label: 'Date',
                    getValue: item => item.date,
                    type: 'text',
                    key:'date',
                    ref: 'date',
                    required:true,
                },
                {
                    label: 'Target',
                    getValue: item => item.target,
                    type: 'text',
                    key:'target',
                    ref: 'target',
                    required:true,
                },
                {
                    label: 'Target Info',
                    getValue: item => item.targetInfo,
                    type: 'text',
                    key:'targetInfo',
                    ref: 'targetInfo',
                    required:true,
                },
                {
                    label: 'Description',
                    getValue: item => item.description,
                    type: 'text',
                    key:'description',
                    ref: 'description',
                    required:true,
                },
                {
                    label: 'File Name',
                    getValue: item => item.fileName,
                    type: 'text',
                    key:'fileName',
                    ref: 'fileName',
                    required:false,
                },
                {
                    label: 'Size',
                    getValue: item => item.size,
                    type: 'text',
                    key:'size',
                    ref: 'size',
                    required:false,
                },
                {
                    label: 'Checksum',
                    getValue: item => item.checksum,
                    type: 'text',
                    key:'chekcsum',
                    ref: 'checksum',
                    required:false,
                },
                {
                    label: 'Default',
                    getValue: item => item.isDefault,
                    type: 'text',
                    key:'isDefault',
                    ref: 'isDefault',
                    required:false,
                },
                {
                    label: 'Info',
                    getValue: item => item.info,
                    type: 'text',
                    key:'info',
                    ref: 'info',
                    required:true,
                },
                {
                    label: 'File Input',
                    key: 'fileInput',
                    ref: 'fileInput',
                    type: 'hidden',
                    required:true
                },

            ],
            addBtnLabel:'Add New',
            showPicker:false,
            pageLoading:false,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1
            },
            page: {
                size:10,
                index:1
            }
           
        }
    },
    mounted(){
        this.getBootloaders(this.initialPage);
    },
    watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
            }
        },
    },
    computed: {
        numberOfPages: function(){
            return Math.ceil(this.data.count/this.page.size)
        }
    },
    methods:{
        getBootloaders(item){
            axios.get('infrastructure/bootloaders?pageIndex='+(item.index-1)+'&pageSize='+item.size)
                .then(res=>{
                    this.data = res.data;
                })
        },
        setSort(item){
            this.page.index = 1;
            this.page.size = item;
            this.getBootloaders(this.page);
        },

        validate(){

            let valid = true;

            this.fields.forEach(field => {

                // Field is requried
                if(field.required) {

                    console.log(field);

                    
                
                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        console.log('invalid', field);
                    }

                }
            });

            return valid;
        },
        openAddNewModal(){
            this.editing = true;
            this.temp.isDefault = false;
        },
        openEditModal(item){
            let obj = Object.assign({},item);
            this.temp = { 
              id:obj.id,
              version:obj.version,
              date:obj.date.substring(0, obj.date.length-9),
              target:obj.target,
              targetInfo:obj.targetInfo,
              description:obj.description,
              isDefault:obj.isDefault,
              info:obj.info
            }
            this.editing = true;
        },
        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },
        createItem(item){
          this.loading = true;  
          if(!this.validate()) {
              Vue.toasted.error('Please fill in the form');
              this.loading = false;
              return; 
          }
          var formData = new FormData();
          for(const key in item){
              if(item.hasOwnProperty(key)){
                  let value = item[key];
                  formData.append(key, value);
              }
          }
          
          for(let value of formData.values()){
              console.log(value);
          }

          for(let value of formData.keys()){
              console.log(value);
          }

          axios.post('bootloaderFiles', formData)
              .then(res=> {
                  Vue.toasted.success('Item created.');
                  this.getBootloaders(this.initialPage);
                  this.closeModals();
                  this.loading = false;
              })
              .catch(err => {
                  
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error processing your request'); 
                  }

                  this.loading = false;

                });
        },
        updateItem(item, keepOpen) {
            // Validation 
            this.loading = true;            
            if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            
            axios.put('bootloaderFiles/'+item.id, item)
                 .then(res => {

                    Vue.toasted.success('Item updated.');
                    this.getBootloaders(this.initialPage);
                    this.closeModals();
                                            
                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },
        deleteItem(item) {
            console.log(item);
            axios.delete('bootloaderFiles/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getBootloaders(this.initialPage);
                })
                .catch(err => {
                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error deleting this item.')
                    }
                   
                });

        },
        closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
            this.showPicker = false;
        },

    }
}
</script>

<template>
	<div class="tab images">
		<div>
			<v-container>
				<v-row>
					<v-col cols='12'>
						<div class="">
								<v-btn :elevation="0" class="under_nav_btn button_green float-left" @click="openAddNewModal">Add New<v-icon>mdi-plus</v-icon></v-btn>
								<v-btn :elevation="0" class="under_nav_btn button_green float-left">Sort by<v-icon>mdi-menu-down</v-icon></v-btn>
							</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div>
			<v-container>
				<v-row>
					<v-col xs='12' sm='6' md='4' lg='2' xl='2' v-for="item in data" :key="item.id">
						<div class="card-green card-border-radius" v-if="data" data-aos="fade">                            
                            <div class="card_image_header">
								<v-row no-gutters>
									<v-col cols='6'>
										<p>{{item.fileName}}</p>
										<p>{{item.category.name}}</p>
									</v-col>
									<v-col cols='6'>
										<a class="float-right" @click="openDeleteModal(item)">
											<v-icon color="gray">mdi-delete-outline</v-icon>
										</a>
									</v-col>
								</v-row>
                               
                            </div>
                            <div class="image_container">
                               <v-img
                                height='210'
                                contain
                               :src="(item) ? getImage(item.fileName) : '/img/images_placeholder2x.png'"
                               />
                            </div>
                        </div>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Image</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field" v-if='!temp.id'>
									<v-file-input
									label='File Input'
									v-model="temp.file"
									:rules="[() => !!temp.file || 'This field is required']"
                                    ref='fileInput'
									show-size
									outlined
									/>
                                    
                                    <v-select
                                        v-if="casinoMediaCategories"
                                        :items='casinoMediaCategories'
                                        item-text="name"
                                        item-value="id"
                                        label="Media Category"
                                        v-model="temp.categoryId"
                                        :rules="[() => !!temp.categoryId || 'This field is required']"
                                        ref='media'
                                        required
                                        outlined
                                    />
                                </div> 
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="!temp.id" :elevation="0" color="green" dark @click="createItem(temp)">
                            Create
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp, true)">
                            Save
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp, false)">
                            Save and Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Image</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
	</div> 
	
</template>
<script>
export default {
	data(){
		return {
			data:null, // Casino Media Files
			casinoMediaCategories:null, //casino media categories
			editing: false,
            deleting: false,
            selected: null,
            loading: false,
			temp:{},
            mediaDim:{},
            fields: [
                {
                    label: 'File Input',
                    ref:"fileInput",
                    required:true
                },
                {
                    label: 'Media Category',
                    ref:"media",
                    required:true
                }
            ]
		}
	},
	mounted(){
		this.getCasinoMediaFiles();
		this.getCasinoMediaCategories()
	},
    watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
            }
        },
    },
	methods: {
		getCasinoMediaFiles(){
			axios.get('system/casinos/'+this.$route.params.casino_id+'/mediaFiles')
				.then(res=>{
					this.data = res.data;
				})
		},
		
		getCasinoMediaCategories(){
			axios.get('casinoMediaCategories')
				.then(res=>{
					this.casinoMediaCategories = res.data;
				})
		},

		openAddNewModal(){
            this.editing = true;
        },

        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },

		validate(){

            let valid = true;

            this.fields.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        imageDimensions(file){
            let tempFile = file;

            var reader = new FileReader();
            var self = this;

            reader.readAsDataURL(tempFile);
            reader.onload = function(e) {
                let image = new Image();

                image.src= e.target.result;
                image.onload = function () {
                    self.mediaDim['height'] = this.height;
                    self.mediaDim['width'] = this.width;      
                }
                               
            };
        },

    createItem(item, keepOpen){
            this.loading = true;
            if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            } 

            this.imageDimensions(item.file);
            item.casinoId = this.$route.params.casino_id;
            var formData = new FormData();


            for(const key in item){
                if(item.hasOwnProperty(key)){
                    let value = item[key];
                    formData.append(key, value);
                }
            }
                       
            //Setting image dimensions 
            //before passing object to API
            for(const key in this.mediaDim){
                if(this.mediaDim.hasOwnProperty(key)){
                    let dimension = this.mediaDim[key];
                    formData.append(key, dimension);
                }
            }
           
			// for(let value of formData.values()){
            //     console.log(value);
            // }

            // for(let value of formData.keys()){
            //     console.log(value);
            // }

            axios.post('casinoMediaFiles', formData)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                     this.getCasinoMediaFiles();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        deleteItem(item) {
            console.log(item);
            axios.delete('casinoMediaFiles/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getCasinoMediaFiles();
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });

        },

		closeModals() {
            this.temp = {};
            this.mediaDim = {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
        },
	}
}
</script>
        <template>
    <div>
        <div style="margin-top:20px">
			<v-row>
                <v-col cols='12'>
                    <div class="">
                            <v-btn :elevation="0" class="under_nav_btn button_purple float-left" @click="openAddNewModal">Add New<v-icon>mdi-plus</v-icon></v-btn>
                            <v-btn :elevation="0" class="under_nav_btn button_purple float-left">Sort by<v-icon>mdi-menu-down</v-icon></v-btn>
                        </div>
                </v-col>
            </v-row>
		</div>
        <div>
			<v-row v-if="data">
                <v-col xs='12' sm='12' md='6' lg="4" v-for="item in data" :key="item.id">
                    <div class="card-purple card-border-radius" v-if="data" data-aos="fade">                            
                        <div class="card_image_header">
                            <v-row no-gutters>
                                <v-col cols='10'>
                                    <p>{{item.name}}</p>
                                    <p>RTP Group: <span class="mix_rtp">{{item.rtpGroup}}</span></p>
                                    <p>Volatility: <span :class="[item.volatility>=50 ? 'mix_high' : 'mix_low']"></span></p>
                                </v-col>
                                <v-col cols='2'>
                                    <a class="float-right" @click="openDeleteModal(item)">
                                        <v-icon color="gray">mdi-delete-outline</v-icon>
                                    </a>
                                    <a class="float-right" @click="openEditModal(item)">
                                        <v-icon color="gray">mdi-pencil-outline</v-icon>
                                    </a>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="image_container">
                            <v-row>
                                <v-col cols='4' v-for="game in item.gameMixGames" :key='game.id'>
                                    <v-img class="game_thumb text-center"
                                        :src='getImage(game.game.mediaFiles[0].fileName)'
                                        max-height='106'
                                        max-width='106'
                                        min-height='106'
                                        min-width='106'
                                    />
                                </v-col>
                            </v-row>
                            <div class="white_gradient"></div>
                        </div>
                    </div>
                </v-col>
            </v-row>	
		</div>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="800">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Game Mix</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                        <span class="modal_step">Step {{step}}/{{noOfSteps}}</span>   
                    </div>
                    <step-pos :step='step' :noOfSteps='noOfSteps'></step-pos>
                    <v-card-text class="cabinet_modal">

                        <!-- Add New Game Mix -->
                        <br>
                        <v-row justify='center' v-show='!temp.id && step===1'> 
                            <v-col cols='6' >
                                <form>
                                    <div class="fields">
                                        <div class="field">
                                            <v-text-field
                                            label='Game Mix Name'
                                            v-model="temp.Name"
                                            :rules="[() => !!temp.Name || 'This field is required']"
                                            ref='mixName'
                                            outlined
                                            />
                                            <v-text-field
                                            label='RTP Group'
                                            v-model="temp.RTPGroup"
                                            :rules="[() => !!temp.RTPGroup || 'This field is required']"
                                            ref='rtpGroup'
                                            outlined
                                            />
                                            <v-text-field
                                            label='Volatility'
                                            v-model="temp.Volatility"
                                            :rules="[() => !!temp.Volatility || 'This field is required']"
                                            ref='mixVolatility'
                                            outlined
                                            />
                                            <v-select
                                            label='Platform'
                                            :items="platforms"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.EGMPlatformId"
                                            :rules="[() => !!temp.EGMPlatformId || 'This field is required']"
                                            ref='egmPlatformId'
                                            outlined
                                            />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                        <v-row  v-if='!temp.id && step === noOfSteps'>
                            <v-col cols="4" class="game_list">
                                <span class="label">Game List</span>
                                <v-list>
                                    <v-list-item-group v-if='availableGames' v-model="selectedList.available">
                                        <v-list-item v-for="item in availableGames" :key="item.id" @click='addSelectGame(item)'>{{item.name}}</v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                            <v-col cols="4">
                                <v-form ref='addForm'>
                                    <div class="fields">
                                        <div class="game_cover">
                                            <span class="label">Game Cover</span>
                                            <v-img v-if='selectedGame'
                                                :src="(selectedGame.coverMedia) ? getImage(this.selectedGame.coverMedia.fileName) : '/img/images_placeholder2x.png'"
                                            />
                                        </div>
                                       
                                        <div class="field" >
                                            <v-text-field
                                            label='Game Name'
                                            :value="(selectedGame) ? selectedGame.name : []"
                                            disabled
                                            outlined
                                            />
                                            <v-text-field
                                            label='SAS Name'
                                            v-model="tempAddGameToMix.SASName"
                                            :rules="[() => !!tempAddGameToMix.SASName || 'This field is required']"
                                            ref="sasName"
                                            :disabled="selectedList.available===null"
                                            outlined
                                            />
                                            <v-text-field
                                            label='SAS Code'
                                            v-model="tempAddGameToMix.SASCode"
                                            :rules="[() => !!tempAddGameToMix.SASCode || 'This field is required']"
                                            ref="sasCode"
                                            :disabled="selectedList.available===null"
                                            outlined
                                            />
                                            <v-text-field
                                            label='Volatility'
                                            v-model="tempAddGameToMix.Volatility"
                                            :rules="[() => !!tempAddGameToMix.Volatility || 'This field is required']"
                                            ref='gameVolatility'
                                            :disabled="selectedList.available===null"
                                            outlined
                                            />
                                            <v-select
                                            :disabled='!selectedGame || selectedList.available===null'
                                            label='Game RTP'
                                            :items="(selectedGame) ? selectedGame.gameRTPs : []"
                                            item-text='value'
                                            item-value='id'
                                            v-model="tempAddGameToMix.GameRTPId"
                                            :rules="[() => !!tempAddGameToMix.GameRTPId || 'This field is required']"
                                            ref='gameRTP'
                                            outlined
                                            />
                                            
                                           <v-btn class="arrow-btn float-right" :disabled='selectedList.mix!==null' elevation="0" @click='addGameToMix(selectedGame)'>
                                               
                                                <v-img height='26' width="42" max-width="42" src="/img/arrow-right.png"/>
                                            </v-btn>
                                            <v-btn class="arrow-btn " :disabled='selectedList.available!==null' elevation="0" @click='removeGameFromMix(selectedGame, false)'>
                                                
                                                <v-img height='26' width="42" max-width="42" src="/img/arrow-left.png"/>
                                            </v-btn>
                                        </div> 
                                    </div>
                                </v-form>
                            </v-col>
                            <v-col cols="4" class="game_list">
                                <span class="label">Game Mix</span>
                                <v-list>
                                    <v-list-item-group v-if='mixGames' v-model="selectedList.mix">
                                        <v-list-item v-for="item in mixGames" :key="item.id" @click='removeSelectGame(item)'>{{item.name}}</v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                        
                        <!-- Edit Game Mix -->
                        <br>
                        <v-row justify='center' v-if='temp.id && step===1'> 
                            <v-col cols='6' >
                                <form>
                                    <div class="fields">
                                        <div class="field">
                                            <v-text-field
                                            label='Game Mix Name'
                                            v-model="temp.name"
                                            :rules="[() => !!temp.name || 'This field is required']"
                                            ref="mixName"
                                            outlined
                                            />
                                            <v-text-field
                                            label='RTP Group'
                                            v-model="temp.rtpGroup"
                                            :rules="[() => !!temp.rtpGroup || 'This field is required']"
                                            ref="rtpGroup"
                                            outlined
                                            />
                                            <v-text-field
                                            label='Volatility'
                                            v-model="temp.volatility"
                                            :rules="[() => !!temp.volatility || 'This field is required']"
                                            ref="mixVolatility"
                                            outlined
                                            />
                                            <v-select
                                            label='Platform'
                                            :items="platforms"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.EGMPlatformId "
                                            :rules="[() => !!temp.EGMPlatformId  || 'This field is required']"
                                            ref="egmPlatformId"
                                            outlined
                                            />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                        <v-row  v-if='temp.id && step === noOfSteps'>
                            <v-col cols="4" class="game_list">
                                <span class="label">Game List</span>
                                <v-list>
                                    <v-list-item-group v-if='availableGames' v-model="selectedList.available">
                                        <v-list-item v-for="item in availableGames" :key="item.id" @click='addSelectGame(item)'>{{item.name}}</v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                            <v-col cols="4">
                                <v-form ref='editForm'>
                                    <div class="fields">
                                        <div class="game_cover">
                                            <span class="label">Game Cover</span>
                                            <v-img v-if='selectedGame'
                                                :src="(selectedGame.coverMedia) ? getImage(this.selectedGame.coverMedia.fileName) : '/img/images_placeholder2x.png'"
                                            />
                                        </div>
                                       
                                        <div class="field" >
                                            <v-text-field
                                            label='Game Name'
                                            :value="(selectedGame) ? selectedGame.name : []"
                                            disabled
                                            outlined
                                            />
                                            <v-text-field
                                            label='SAS Name'
                                            v-model="tempAddGameToMix.SASName"
                                            :rules="[() => !!tempAddGameToMix.SASName || 'This field is required']"
                                            ref='sasName'
                                            :disabled="selectedList.available===null"
                                            outlined
                                            />
                                            <v-text-field
                                            label='SAS Code'
                                            v-model="tempAddGameToMix.SASCode"
                                            :rules="[() => !!tempAddGameToMix.SASCode || 'This field is required']"
                                            ref="sasCode"
                                            :disabled="selectedList.available===null"
                                            outlined
                                            />
                                            <v-text-field
                                            label='Volatility'
                                            v-model="tempAddGameToMix.Volatility"
                                            :rules="[() => !!tempAddGameToMix.Volatility || 'This field is required']"
                                            ref='gameVolatility'
                                            :disabled="selectedList.available===null"
                                            outlined
                                            />
                                            <v-select
                                            :disabled='!selectedGame || selectedList.available===null'
                                            label='Game RTP'
                                            :items="(selectedGame) ? selectedGame.gameRTPs : []"
                                            item-text='value'
                                            item-value='id'
                                            v-model="tempAddGameToMix.GameRTPId"
                                            :rules="[() => !!tempAddGameToMix.GameRTPId || 'This field is required']"
                                            ref='gameRTP'
                                            outlined
                                            />
                                           
                                            <v-btn class="arrow-btn float-right" :disabled='selectedList.mix!==null' elevation="0" @click='addGameToMix(selectedGame)'>
                                               
                                                <v-img height='26' width="42" max-width="42" src="/img/arrow-right.png"/>
                                            </v-btn>
                                            <v-btn class="arrow-btn " :disabled='selectedList.available!==null' elevation="0" @click='removeGameFromMix(selectedGame, true)'>
                                                
                                                <v-img height='26' width="42" max-width="42" src="/img/arrow-left.png"/>
                                            </v-btn>
                                           
                                        </div> 
                                    </div>
                                </v-form>
                            </v-col>
                            <v-col cols="4" class="game_list">
                                <span class="label">Game Mix</span>
                                <v-list>
                                    <v-list-item-group v-if='mixGames' v-model="selectedList.mix">
                                        <v-list-item v-for="item in mixGames" :key="item.id" @click='removeSelectGame(item)'>{{item.name}}</v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>

                        <v-btn class="modal_btn" v-if="step===1" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" v-if="step!==1" :elevation="0" color="#ada7a7" dark @click="step-=1">
                            Back
                        </v-btn>


                        <!-- Buttons for Add New Modal -->
                        <v-btn class="modal_btn"  v-if="!temp.id && step<noOfSteps" :elevation="0" color="green" dark @click="createBasicItem(temp)">
                            Next
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="!temp.id && step===noOfSteps" :elevation="0" color="green" dark @click="postItem(temp, false)">
                            Save
                        </v-btn>
                       
                        
                        <!-- Buttons for Edit Modal -->
                        <v-btn class="modal_btn"  v-if="temp.id && step<noOfSteps" :elevation="0" color="green" dark @click="mapTemp(temp)">
                            Next
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && step===noOfSteps" :elevation="0" color="green" dark @click="updateItem(temp, false)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Image</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div> 
</template>
<script>
import StepProgress from '@/components/StepProgress.vue';
export default {
    components: {
        'step-pos':StepProgress
    },
    data(){
        return {
            data: null, //holds game mixes by manufacturer
            platforms:null, //holds platforms by manufaturers
            games:null, //holds games by manufacturer

            selectedList:{ 
                available:null,
                mix:null
            }, //keeps track of selected list

            availableGames:null, //display available games that can be added to game mix
            mixGames:[], //display games in game Mix

            tempAddGameToMix:{}, //temp obj holds game data to be added to new game mix 
            tempNewGamesMix:[], //temp obj holds new game mix data
            selectedGame:null,
            indexOfGame:null, //index of seletcted game from mix

            step: 1, //first step of modal
            noOfSteps: 2, //total steps of modal

            editing: false,
            deleting: false,
            selected: null,
            loading: false,
			temp:{},

            mixFields:[
                {
                    label:'Mix Name',
                    ref:'mixName',
                    required:true
                },
                {
                    label:'RTP Group',
                    ref:'rtpGroup',
                    required:true
                },
                {
                    label:'Mix Volatility',
                    ref:'mixVolatility',
                    required:true
                },
                {
                    label:'EGM Platform',
                    ref:'egmPlatformId',
                    required:true
                }
            ],
            gameFields:[
                {
                    label:'SAS Name',
                    ref:'sasName',
                    required:true
                },
                {
                    label:'SAS Code',
                    ref:'sasCode',
                    required:true
                },
                {
                    label:'Game Volatility',
                    ref:'gameVolatility',
                    required:true
                },
                {
                    label:'Game RTP',
                    ref:'gameRTP',
                    required:true
                },
            ]
        }
    },
    mounted(){
        this.getManufacturerGameMixes();
        this.getManufacturerPlatforms();
        this.getManufacturerGames();
    },
     watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
                this.selected = null;
                this.selectedGame = null;
                this.selectedList = { available: null, mix: null}
                this.tempAddGameToMix = {};
                this.tempNewGamesMix = [];
                this.step = null;
                this.mixGames = [];
            }
        },
    },
    methods: {
        getManufacturerGameMixes(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id+'/gameMixes')
                .then(res=>{
                    this.data = res.data;
            })
        },

        getManufacturerPlatforms(){
            axios.get('egmManufacturers/'+this.$route.params.manufacturer_id+'/platforms')
                .then(res=>{
                    this.platforms = res.data;
            })
        },

        getManufacturerGames(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id+'/games')
                .then(res=>{
                    this.games = res.data;
                })
        },

        addSelectGame(item){
            this.tempAddGameToMix = {};
            this.selectedGame = Object.assign({},item);
            this.selectedList.mix = null;

            if(!this.temp.hasOwnProperty('id')){
                this.$refs.addForm.resetValidation();
            }
            else{
                this.$refs.editForm.resetValidation();
            }
        },

        removeSelectGame(item){
            this.selectedGame = Object.assign({},item);
            this.indexOfGame =  this.tempNewGamesMix.findIndex(el => el.GameId === this.selectedGame.id && el.status === 'Active');
            this.selectedList.available = null;
            this.tempAddGameToMix = this.tempNewGamesMix[this.indexOfGame];
        },

        addGameToMix(game){
            
            this.tempAddGameToMix.GameId = game.id;
            this.tempAddGameToMix.status = 'Active';

            if(!this.validate(this.gameFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            this.tempNewGamesMix.push(this.tempAddGameToMix);

            this.mixGames = this.games.filter(item => {
                return this.tempNewGamesMix.some(el => {
                    return el.GameId === item.id && el.status === 'Active'
                });
            });

            this.availableGames = this.games.filter(item => {
                return this.tempNewGamesMix.every(el => {
                    return el.GameId !== item.id || el.status === 'Deleted'
                });
            });
                      
            this.tempAddGameToMix = {}
           
            this.selectedGame = null;

            if(!this.temp.hasOwnProperty('id')){
                this.$refs.addForm.resetValidation();
            }
            else{
                this.$refs.editForm.resetValidation();
            }
            
        },

        removeGameFromMix(param, markDeleted){
            
            if(markDeleted && this.tempNewGamesMix[this.indexOfGame].Id){
                this.tempNewGamesMix[this.indexOfGame].status = "Deleted";

                this.mixGames = this.games.filter(item => {
                    return this.tempNewGamesMix.some(el => {
                        return el.GameId === item.id && el.status === 'Active'
                    });
                });

                this.availableGames = this.games.filter(item => {
                    return this.tempNewGamesMix.every(el => {
                        return el.GameId !== item.id || el.status === 'Deleted'
                    });
                });
            }
            else{
                this.tempNewGamesMix.splice(this.indexOfGame,1);

                this.mixGames = this.games.filter(item => {
                    return this.tempNewGamesMix.some(el => {
                        return el.GameId === item.id && el.status === 'Active'
                    });
                });

                this.availableGames = this.games.filter(item => {
                    return this.tempNewGamesMix.every(el => {
                        return el.GameId !== item.id || el.status === 'Deleted'
                    });
                });
            }
            this.tempAddGameToMix = {};
            this.selectedGame = null;
             if(!this.temp.hasOwnProperty('id') ){
                this.$refs.addForm.resetValidation();
            }
            else{
                this.$refs.editForm.resetValidation();
            }
           
        },

        validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        openAddNewModal(){
            this.editing = true;
            this.step = 1;
            this.availableGames = this.games;
           
        },

        openEditModal(item){
            this.editing = true;
            this.step = 1;
            this.temp = Object.assign({}, item);
            this.tempNewGamesMix = item.gameMixGames.map(obj=>{
                let nObj = { 
                    Id: obj.id,
                    GameId: obj.game.id,
                    SASCode: obj.sasCode,
                    SASName: obj.sasName,
                    GameRTPId: obj.gameRTPId,
                    Volatility: obj.volatility,
                    status: obj.game.status
                }
                return nObj;
            });
            this.temp.EGMManufacturerId = item.egmPlatform.egmManufacturerId
            this.temp.EGMPlatformId = item.egmPlatform.id;
            delete this.temp.egmPlatform;
        },

        openDeleteModal(selected){
            this.selected = selected;
            this.deleting = true;
        },

        closeModals() {
            this.temp = {};
            this.selected = null;
            this.selectedGame = null;
            this.selectedList = { available: null, mix: null}
            this.tempAddGameToMix = {};
            this.tempNewGamesMix = [];
            this.editing = false;
            this.deleting = false;
            this.step = null;
        },

        createBasicItem(item){
            this.loading = true;
            item.EGMManufacturerId = this.$route.params.manufacturer_id;
            if(!this.validate(this.mixFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            this.step+=1;
        },

        mapTemp(temp){
            this.step+=1;

            this.mixGames = this.games.filter(item => {
                return this.tempNewGamesMix.some(el => {
                    return el.GameId === item.id
                });
            });

            this.availableGames = this.games.filter(item => {
                return this.tempNewGamesMix.every(el => {
                    return el.GameId !== item.id
                });
            });
        },

        postItem(item, keepOpen){
            item.GameMixGames = this.tempNewGamesMix;
            this.loading = true;
            
            console.log(item);
            

            axios.post('gameMixes', item)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                    this.getManufacturerGameMixes();
                    if(!keepOpen){
                        this.closeModals();
                    }
                    this.temp={};
                    this.loading = false;
                    this.$emit('refreshStats');
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        updateItem(item, keepOpen){
            item.gameMixGames = this.tempNewGamesMix;
            this.loading = true;
            
           
            console.log(item);
            

            axios.put('gameMixes/'+item.id, item)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                    this.getManufacturerGameMixes();
                    if(!keepOpen){
                        this.closeModals();
                    }
                   
                    this.loading = false;
                    this.temp={};
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        deleteItem(item) {
            console.log(item);
            axios.delete('gameMixes/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getManufacturerGameMixes();
                    this.$emit('refreshStats');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });
        },
    }
}
</script>
<template>
    <div class="tab links" >
        <v-container>
            <v-row>
                <v-col xs='12' sm='12' md='12' lg='6' xl='6'>
                    <div class="card card-green" v-if="data" data-aos="fade">
                            <div class="card_header">
                            <span class="float-left">URL</span>
                            <span class="float-right">
                                <add-new-item
                                 :label='addBtnLabel'
                                @openSpecificCanAdd='openAddNewModal'
                                />
                            </span>
                        </div>
                        <crud-table class="gen_table" :data='data' :fields='fields' :canEdit="true" :canDelete="true" @edit="openEditModal" @delete="openDeleteModal"></crud-table>       
                    </div>  
                </v-col>
            </v-row>
        </v-container>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>URL</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field" v-if='!temp.id'>
                                    <v-select
                                        v-if="casinoLinkTypes"
                                        :items='casinoLinkTypes'
                                        item-text="name"
                                        item-value="id"
                                        label="Link Type"
                                        v-model="temp.casinoLinkTypeId"
                                        :rules="[() => !!temp.casinoLinkTypeId || 'This field is required']"
                                        ref='casinoLinkTypeId'
                                        
                                        outlined
                                    />
                                    
                                    <v-text-field 
                                        label="URL"
                                        v-model="temp.url"
                                        :rules="[() => !!temp.url || 'This field is required']"
                                        ref='url'
                                        outlined
                                    />
                                    
                                </div> 
                                <div class="field" v-if='temp.id'>
                                   
                                    <v-text-field 
                                        label="Link Type"
                                        v-model="temp.casinoLinkType.name"
                                        :rules="[() => !!temp.casinoLinkTypeId || 'This field is required']"
                                        ref='casinoLinkTypeId'
                                        disabled
                                        outlined
                                    />
                                    <v-text-field 
                                        label="URL"
                                        v-model="temp.url"
                                        :rules="[() => !!temp.url || 'This field is required']"
                                        ref='url'
                                        outlined
                                    />
                                   
                                </div> 
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="!temp.id" :elevation="0" color="green" dark @click="createItem(temp)">
                           Add New
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp, false)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>URL</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>                  
    </div>
</template>
<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
    components: {
        'add-new-item': AddNewItem,
        'crud-table':CrudTable,
    },
    data(){
        return {
            data:null, //Holds all links
            casinoLinkTypes:null, //all link types
            editing: false,
            deleting: false,
            selected: null,
            loading: false,
			temp:{},
            fields:[
                {
                    label: 'Type',
                    getValue: item => item.casinoLinkType.name,
                    type: 'text',
                    key:'casinoLinkType',
                    ref: 'casinoLinkTypeId',
                    required:true,
                },
                {
                    label: 'Address',
                    getValue: item => item.url,
                    type: 'text',
                    key:'url',
                    ref: 'url',
                    required:true,
                }
            ],
            addBtnLabel:'Add New'
           
        }
    },
    mounted(){
        this.getCasinoLinks();
        this.getCasinoLinkTypes();
    },
    watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = {};
            }
        },
    },
    methods:{
        getCasinoLinks() {
            axios.get('system/casinos/'+this.$route.params.casino_id+'/links')
                .then(res=>{
                    this.data = res.data;
                })
        },

        getCasinoLinkTypes(){
            axios.get('casinoLinkTypes')
                .then(res=>{
                    this.casinoLinkTypes = res.data;
                })
        },

        validate(){

            let valid = true;

            this.fields.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    
                
                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        openAddNewModal(){
            this.editing = true;
        },

        openEditModal(item){
            this.temp = Object.assign({},item);
            this.editing = true;
        },

        openDeleteModal(selected){
            this.selected = selected
            this.deleting = true;
        },

         createItem(item, keepOpen){
            item.casinoId = this.$route.params.casino_id;
            this.loading = true;
            
           if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            axios.post('casinoLinks', item)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                    this.getCasinoLinks();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        updateItem(item, keepOpen) {
            item.casinoLinkTypeId = item.casinoLinkType.id;
            item.casinoId = this.$route.params.casino_id;
            // Validation 
            this.loading = true;            
            if(!this.validate()) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }
            

            // Remove all child objects
            // from the model before seding it to the API
            var cleanItem = Object.assign({}, item);
            for (let prop in cleanItem) {
                if(typeof cleanItem[prop] == 'object') {
                    delete cleanItem[prop];
                }
            }
            console.log(cleanItem);
            axios.put('casinoLinks/'+cleanItem.id, cleanItem)
                 .then(res => {

                    Vue.toasted.success('Item created.');
                      this.getCasinoLinks();

                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },
        deleteItem(item) {
            console.log(item);
            axios.delete('casinoLinks/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getCasinoLinks();
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });

        },
        closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
        },

    }
}
</script>
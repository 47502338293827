<template>
<div class="page casino">
	<div class="header">
		<v-container>
			<div class="entity" v-if="data" data-aos="fade">
						<div class="title">{{data.name}}</div>
						<div class="subtitle">{{data.companyName}}</div>
					</div>
		</v-container>
	</div>
	<div class="navigation navigation-green">
		<v-container>
			<v-row>
				<v-col cols="12">
					<tab-nav :tabNames="tabNames"></tab-nav>
				</v-col>
			</v-row>
		</v-container>
	</div>
	<div class="content">
		<router-view></router-view>
	</div>
</div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
export default {
	components:{
		'tab-nav':TabNav
	},
	data(){
		return {
			data:null,
			tabNames:[
				{
					label:'Images',
					route: 'image'
				},
				{
					label:'Links',
					route:'link'
				},
				{
					label:'Business Settings',
					route:'businessSetting'
				},
				{
					label:'Session Management Policy',
					route:'sessionManage'
				},
				{
					label:'Contacts',
					route:'contact'
				},
				{
					label:'History',
					route:'history'
				}
			]
		}
	},
	mounted() {
		axios.get('casinos/'+this.$route.params.casino_id)
			 .then(res=>{
				 this.data = res.data
			 });
	},
	methods: {

	}
}
</script>
<template>
	<div class="tab sessionManage" >
		<div>
			<v-container>
				<v-row  v-if="data" data-aos="fade">
					<v-col xs='12' sm='12' md='6' lg='3' xl='3'>
                        <v-row no-gutters>
                            <v-col cols='12'>
                                <p class="info-pill">Info</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12'>
                                <div class="card card-blue">                            
                                    <div class="card_header">
                                        <v-row no-gutters v-if='selectedFunction'>
                                            <v-col cols='9'>
                                                <div class="master-function-menu">
                                                    <v-menu offset-y attach>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn 
                                                                color="#7AA7D0"
                                                                dark
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :elevation="0" 
                                                                class="function-select"
                                                            >{{selectedFunction.name}} <v-icon>mdi-chevron-down</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item
                                                            v-for="(item) in casinoFunctions"
                                                            :key="item.id"
                                                            >
                                                            <v-list-item-title @click='putCasinoMasterFunction(item)'>{{ item.name }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </div>
                                                <div class='time-reference'>
                                                    <v-text-field 
                                                        outlined 
                                                        height='40' 
                                                        type='number' 
                                                        hint='Time Reference in minutes'
                                                        v-model='timeReference'
                                                        append-icon='mdi-plus-circle-outline'
                                                        @click:append='putTimeReference'
                                                    ></v-text-field>
                                                </div>
                                            </v-col>
                                            <v-col cols='3'>
                                                <div class="add-level-button text-blue">
                                                    <add-new-item
                                                    :label='addBtnLabel'
                                                    @openSpecificCanAdd='openAddNewModal'
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <crud-table-select class="gen_table player_level" :data='data' :fields='levelFields' :canDelete='true' @delete='openDeleteModal' @selectItem="getLevelFeatures" :canSelect="true"></crud-table-select>
                                </div>
                            </v-col>
                        </v-row>
					</v-col>

                    <v-col xs='12' sm='12' md='6' lg='3' xl='3' v-if='levelFeatureData' data-aos="fade">
                        <v-row no-gutters>
                            <v-col cols='12'>
                                <p class="benefits">Benefits</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12'>
                                <div class="card card-green">
                                    <div class="card_header">
                                        <v-row no-gutters>
                                            <v-col cols='6'>
                                                <p class='card_heading'>{{levelFeatureData[0].name}}</p>
                                            </v-col>
                                            <v-col cols='6'>
                                                <a class="float-right" @click="openEditModal(levelFeatureData[0])">
                                                    <v-icon color="gray">mdi-pencil-outline</v-icon>
                                                </a>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="table_container gen_table small_pad">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr v-for='feature in levelFeatureData[0].features' :key="feature.id">
                                                        <td>{{feature.type.name}}:</td>
                                                        <td v-if='feature.typeId === 2'>{{feature.value}} sec</td>
                                                        <td v-if='feature.typeId !== 2'>{{feature.value}}</td>
                                                        <td><v-icon :class="{active: feature.isVisible}" color='rgb(199 199 199)' @click='putVisibility(feature)'>mdi-eye</v-icon></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col xs='12' sm='12' md='12' lg='6' xl='6' v-if='gamingLevels' data-aos='fade'>
                        <v-row no-gutters>
                            <v-col cols='6' class="align-content-center">
                                <p class='ruleset'>Responsible Gaming</p>
                            </v-col>
                            <v-col cols='6' class="justify-end">
                                <v-btn :elevation="0" class="under_nav_btn button_orange float-right" @click="openCreateRGModal">Add RG Ruleset<v-icon>mdi-plus</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        
                        <v-row >
                            <v-col cols='6' v-for='level in gamingLevels' :key='level.id'>
                                <div class="card card-orange">
                                    <div class="card_header">
                                        <v-row no-gutters>
                                            <v-col cols='6'>
                                                <p class='card_heading'>{{level.name}}</p>
                                            </v-col>
                                            <v-col cols='6'>
                                                <a class="float-right" @click="openDeleteRGModal(level)">
                                                    <v-icon color="gray">mdi-delete-outline</v-icon>
                                                </a>
                                                <a class="float-right" @click="openEditRGModal(level)">
                                                    <v-icon color="gray">mdi-pencil-outline</v-icon>
                                                </a>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <p class='rule-type'>Rules:</p>
                                    <div class="table_container gen_table small_pad">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th>Rules applications</th>
                                                        <th></th>
                                                        <th>{{level.mode}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for='rule in level.rules' :key="rule.id">
                                                        <td class="grayed">{{rule.function.name}}:</td>
                                                        <td>{{rule.period.name}}</td>
                                                        <td>{{rule.value}}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                    <p class='rule-type mt-20'>Actions:</p>
                                    <div class="table_container gen_table small_pad">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr v-for='feature in level.features' :key="feature.id">
                                                        <td class="grayed">{{feature.type.name}}:</td>
                                                        <td v-if='feature.type.dataType === "String"'>{{feature.stringValue}}</td>
                                                        <td v-if='feature.type.dataType === "Integer"'>{{feature.numericValue}}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
				</v-row>
			</v-container>
		</div>
		<div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="550">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p v-if='!gaming'>Player Level</p>
                        <p v-if='gaming'>Responsible Gaming Ruleset</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">

                                <!-- ADD Dialog -->
                                <div class="field" v-if='!temp.id && !gaming'>
                                    <v-text-field
                                        label= 'Name'
                                        ref="name"
                                        v-model="temp.name"
                                        :rules="[() => !!temp.name || 'This field is required']"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Order Number'
                                        ref="orderNumber"
                                        v-model="temp.orderNumber"
                                        :rules="[() => !!temp.orderNumber || 'This field is required']"
                                        type="number"
                                        min="0"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Minimum Value'
                                        ref="minValue"
                                        v-model="temp.minValue"
                                        :rules="[() => !!temp.minValue || 'This field is required']"
                                        type="number"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Maximum Value'
                                        ref="maxValue"
                                        v-model="temp.maxValue"
                                        :rules="[() => !!temp.maxValue || 'This field is required']"
                                        type="number"
                                        outlined
                                    />
                                    <v-text-field 
                                        v-for='(feature,index) in featureTypes' 
                                        :key='index'
                                        :label= feature.name
                                        :ref="fields[index].ref"
                                        v-model="temp.features[index].value"
                                        :rules="[() => !!temp.features[index].value || 'This field is required']"
                                        append-outer-icon="mdi-eye"
                                        @click:append-outer="setVisibility(temp.features[index])"
                                        :class="{isVisible:temp.features[index].isVisible}"
                                        type="number"
                                        outlined
                                    />
                                </div> 

                                <div class="field" v-if='!temp.id && gaming'>
                                    <v-text-field
                                        label= 'Name'
                                        ref="ruleName"
                                        v-model="temp.name"
                                        :rules="[() => !!temp.name || 'This field is required']"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Order Number'
                                        ref="ruleOrderNumber"
                                        v-model="temp.orderNumber"
                                        :rules="[() => !!temp.orderNumber || 'This field is required']"
                                        type="number"
                                        min="0"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Mode'
                                        ref="mode"
                                        v-model="temp.mode"
                                        :rules="[() => !!temp.mode || 'This field is required']"
                                        outlined
                                    />
                                    <div class="field_actions">
                                        <span>Rules:</span>
                                        <v-icon @click="addNewRuleRow">mdi-plus-circle-outline</v-icon>
                                    </div>
                                    <v-row v-for='(item,index) in temp.rules' :key='index'>
                                        <v-col cols='4'>
                                            <v-select
                                                label='Rule'
                                                :items="casinoFunctions"
                                                item-text='name'
                                                item-value='id'
                                                v-model="temp.rules[index].functionId"
                                                :rules="[() => !!temp.rules[index].functionId || 'This field is required']"
                                                :ref='"rule"+index'
                                                outlined
                                                />
                                        </v-col>
                                        <v-col cols='4'>
                                            <v-select
                                                label='Time Period'
                                                :items="timePeriods"
                                                item-text='name'
                                                item-value='id'
                                                v-model="temp.rules[index].periodId"
                                                :rules="[() => !!temp.rules[index].periodId || 'This field is required']"
                                                :ref='"timePeriod"+index'
                                                outlined
                                                />
                                        </v-col>
                                        <v-col cols='4'>
                                            <v-text-field
                                                label= 'Rule Value'
                                                :ref="'ruleValue'+index"
                                                v-model="temp.rules[index].value"
                                                :rules="[() => !!temp.rules[index].value || 'This field is required']"
                                                type="number"
                                                min="0"
                                                append-outer-icon="mdi-delete-outline"
                                                @click:append-outer="removeRuleRow(item,index)"
                                                outlined
                                            />
                                        </v-col>
                                    </v-row>
                                    <div class="field_actions">
                                        <span>Actions:</span>
                                        <v-icon @click="addNewActionRow">mdi-plus-circle-outline</v-icon>
                                    </div>
                                    <v-row v-for='(item,i) in temp.features' :key='i'>
                                        <v-col cols='6'>
                                            <v-select
                                                label='Action'
                                                :items="responsabilityFeatureTypes"
                                                item-text='name'
                                                item-value='id'
                                                v-model="temp.features[i].typeId"
                                                :rules="[() => !!temp.features[i].typeId || 'This field is required']"
                                                @change='forceRender'
                                                :ref='"action"+i'
                                                outlined
                                                />
                                        </v-col>
                                        <v-col cols='6'>
                                            <v-text-field
                                                v-if='temp.features[i].typeId === 1'
                                                label= 'Action Value'
                                                :ref="'actionValue'+i"
                                                v-model="temp.features[i].stringValue "
                                                :rules="[() => !!temp.features[i].stringValue || 'This field is required']"
                                                append-outer-icon="mdi-delete-outline"
                                                @click:append-outer="removeActionRow(item,i)"
                                                outlined
                                            />
                                            <v-text-field
                                                v-if='temp.features[i].typeId === 3'
                                                label= 'Action Value'
                                                :ref="'actionValue'+i"
                                                v-model=" temp.features[i].numericValue"
                                                :rules="[() => !!temp.features[i].numericValue || 'This field is required']"
                                                append-outer-icon="mdi-delete-outline"
                                                @click:append-outer="removeActionRow(item,i)"
                                                type="number"
                                                min="0"
                                                outlined
                                            />
                                        </v-col>
                                    </v-row>
                                    
                                </div>

                                <!-- EDIT Dialog-->
                                <div class="field" v-if='temp.id && !gaming'>
                                    <v-text-field
                                        label= 'Name'
                                        ref="name"
                                        v-model="temp.name"
                                        :rules="[() => !!temp.name || 'This field is required']"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Order Number'
                                        ref="orderNumber"
                                        v-model="temp.orderNumber"
                                        :rules="[() => !!temp.orderNumber || 'This field is required']"
                                        type="number"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Minimum Value'
                                        ref="minValue"
                                        v-model="temp.minValue"
                                        :rules="[() => !!temp.minValue || 'This field is required']"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Maximum Value'
                                        ref="maxValue"
                                        v-model="temp.maxValue"
                                        :rules="[() => !!temp.maxValue || 'This field is required']"
                                        outlined
                                    />
                                    <v-text-field 
                                        v-for='(feature,index) in temp.features' 
                                        :key='index'
                                        :label= feature.type.name
                                        :ref="fields[index].ref"
                                        v-model="temp.features[index].value"
                                        :rules="[() => !!temp.features[index].value || 'This field is required']"
                                        append-outer-icon="mdi-eye"
                                        @click:append-outer="setVisibility(temp.features[index])"
                                        :class="{isVisible:temp.features[index].isVisible}"
                                        outlined
                                    />
                                </div> 

                                <div class="field" v-if='temp.id && gaming'>
                                    <v-text-field
                                        label= 'Name'
                                        ref="name"
                                        v-model="temp.name"
                                        :rules="[() => !!temp.name || 'This field is required']"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Order Number'
                                        ref="orderNumber"
                                        v-model="temp.orderNumber"
                                        :rules="[() => !!temp.orderNumber || 'This field is required']"
                                        type="number"
                                        min="0"
                                        outlined
                                    />
                                    <v-text-field
                                        label= 'Mode'
                                        ref="minValue"
                                        v-model="temp.mode"
                                        :rules="[() => !!temp.mode || 'This field is required']"
                                        outlined
                                    />
                                    <div class="field_actions">
                                        <span>Rules:</span>
                                        <v-icon @click="addNewRuleRow">mdi-plus-circle-outline</v-icon>
                                    </div>
                                    <v-row v-for='(item,index) in temp.rules' :key='index'>
                                        <v-col cols='4'>
                                            <v-select
                                                label='Rule'
                                                :items="casinoFunctions"
                                                item-text='name'
                                                item-value='id'
                                                v-model="temp.rules[index].functionId"
                                                :rules="[() => !!temp.rules[index].functionId || 'This field is required']"
                                                ref='rule'
                                                outlined
                                                />
                                        </v-col>
                                        <v-col cols='4'>
                                            <v-select
                                                label='Time Period'
                                                :items="timePeriods"
                                                item-text='name'
                                                item-value='id'
                                                v-model="temp.rules[index].periodId"
                                                :rules="[() => !!temp.rules[index].periodId || 'This field is required']"
                                                ref='timePeriod'
                                                outlined
                                                />
                                        </v-col>
                                        <v-col cols='4'>
                                            <v-text-field
                                                label= 'Rule Value'
                                                ref="ruleValue"
                                                v-model="temp.rules[index].value"
                                                :rules="[() => !!temp.rules[index].value || 'This field is required']"
                                                type="number"
                                                min="0"
                                                append-outer-icon="mdi-delete-outline"
                                                @click:append-outer="removeRuleRow(item, index)"
                                                :class="{itemDeleted: temp.rules[index].status == 'Deleted'}"
                                                outlined
                                            />
                                        </v-col>
                                    </v-row>
                                   <div class="field_actions">
                                        <span>Actions:</span>
                                        <v-icon @click="addNewActionRow">mdi-plus-circle-outline</v-icon>
                                    </div>
                                    <v-row v-for='(item,index) in temp.features' :key='index'>
                                        <v-col cols='6'>
                                            <v-select
                                                label='Action'
                                                :items="responsabilityFeatureTypes"
                                                item-text='name'
                                                item-value='id'
                                                v-model="temp.features[index].typeId"
                                                :rules="[() => !!temp.features[index].typeId || 'This field is required']"
                                                ref='action'
                                                outlined
                                                />
                                        </v-col>
                                        <v-col cols='6'>
                                            <v-text-field
                                                v-if='temp.features[index].typeId === 1'
                                                label= 'Action Value'
                                                ref="featureValue"
                                                v-model="temp.features[index].stringValue "
                                                :rules="[() => !!temp.features[index].stringValue || 'This field is required']"
                                                append-outer-icon="mdi-delete-outline"
                                                @click:append-outer="removeActionRow(item,index)"
                                                :class="{itemDeleted: temp.features[index].status == 'Deleted'}"
                                                outlined
                                            />
                                            <v-text-field
                                                v-if='temp.features[index].typeId === 3'
                                                label= 'Action Value'
                                                ref="featureValue"
                                                v-model=" temp.features[index].numericValue"
                                                :rules="[() => !!temp.features[index].numericValue || 'This field is required']"
                                                append-outer-icon="mdi-delete-outline"
                                                @click:append-outer="removeActionRow(item,index)"
                                                :class="{itemDeleted: temp.features[index].status == 'Deleted'}"
                                                type="number"
                                                min="0"
                                                outlined
                                            />
                                        </v-col>
                                    </v-row>
                                    
                                </div>
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <!-- Player Level -->
                        <v-btn class="modal_btn"  v-if="!temp.id && !gaming" :elevation="0" color="green" dark @click="createItem(temp)">
                            Create
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && !gaming" :elevation="0" color="green" dark @click="updateItem(temp)">
                            Save
                        </v-btn>
                        <!-- Responsible Gaming Rule -->
                         <v-btn class="modal_btn"  v-if="!temp.id && gaming" :elevation="0" color="green" dark @click="createGamingRule(temp)">
                            Create Rule
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && gaming" :elevation="0" color="green" dark @click="updateGamingRule(temp)">
                            Save Rule
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p v-if='!gaming'>Player Level</p>
                        <p v-if='gaming'>Responsible Gaming Ruleset</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <!-- Player Level -->
                        <v-btn class="modal_btn" :elevation="0" v-if="!gaming" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                        <!-- Responsible Gaming Rule -->
                        <v-btn class="modal_btn" :elevation="0" v-if="gaming" color="red" dark @click="deleteGamingRule(selected)">
                            Delete Rule
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
	</div> 
	
</template>
<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTableSelect from '@/components/tables/CrudTableSelect.vue';

export default {
    components: {
        'add-new-item': AddNewItem,
        'crud-table-select':CrudTableSelect
    },
	data(){
		return {
			data:null,  // Casino Player Level
            levelFeatureData:null,
            gamingLevels:null,
            casinoSettings:null, //Casino Settings
            featureTypes: null, //Casino Player Level Feature Types
            casinoFunctions :null, //Casino Player Level Functions
            selectedFunction :null, //Selected Casino Player Level Function
            timeReference:'',
            timePeriods:null,
            responsabilityFeatureTypes:null,

            reset: null,
			editing: false,
            gaming: false,
            deleting: false,
            selected: null,
            loading: false,
            isLevelSelected:false,
			temp:{},
            
            fields: [
                {
                    label:'Parallel Sessions',
                    ref:"parallelSess",
                    required:true
                },
                {
                    label: 'Timeout per Session',
                    ref:"timeoutSess",
                    required:true
                },
                {
                    label: 'ForceExitBelowCredits',
                    ref:"belowCredits",
                    required:true
                },
                {
                    label: 'ForceExitTimeOut',
                    ref:"timeout",
                    required:true
                },
                {
                    label:'Name',
                    ref:"name",
                    required:true
                },
                {
                    label: 'Minimum Value',
                    ref:"minValue",
                    required:true
                },
                {
                    label: 'Maximum Value',
                    ref:"maxValue",
                    required:true
                },
                {
                    label: 'Order Number',
                    ref:"orderNumber",
                    required:true
                },

            ],
            levelFields: [
                {
                    label: 'Name',
                    getValue: item => item.name,
                    type: 'text',
                    key:'name',
                    ref: 'name',
                    required:true,
                },
            ],
            ruleFields:[],

            featureFields:null,
            addBtnLabel:'Add New',
		}
	},
	mounted(){
        this.getCasinoPlayerLevels();
        this.getCasinoPlayerLevelFeatureTypes();
        this.getCasinoPlayerLevelFunctions();   
        this.getTimePeriods();
        this.getPlayerResponsibilityLevelFeatureTypes();
	},
    
	methods: {
        getCasinoPlayerLevels(item){
            axios.get('system/casinos/'+this.$route.params.casino_id+'/playerLevels')
                .then(res=>{
                    this.data = res.data;

                    if(this.isLevelSelected){
                        this.levelFeatureData = this.data.filter(level=>level.id === item.id);
                    }
                })
        },

        getLevelFeatures(item){
            this.isLevelSelected = true;

            this.levelFeatureData = this.data.filter(level=>level.id === item.id);
            
            axios.get('system/casinos/'+this.$route.params.casino_id+'/playerLevels/'+item.id+'/playerResponsibilityLevels')
                .then(res=>{
                    this.gamingLevels = res.data;
                });
        },

        getCasinoPlayerLevelFeatureTypes(){
            axios.get('casinoPlayerLevelFeatureTypes')
                .then(res=>{
                    this.featureTypes = res.data;
                    this.featureFields = this.featureTypes.map(obj=>{
                        let nObj = {};
                        nObj = {
                            label: obj.name,
                            getValue: item => item.value,
                            type: 'text',
                            key: obj.code,
                            ref: obj.code,
                            required:true
                        };
                        return nObj;
                    });
                });
        },

        getCasinoPlayerLevelFunctions(){
            axios.get('casinoPlayerLevelFunctions')
                .then(res=>{
                    this.casinoFunctions = res.data;
                    this.getCasinoSettings();
                });
        },

        getCasinoSettings(){
            axios.get('casinoSettings/'+this.$route.params.casino_id)
                .then(res=>{
                    this.casinoSettings = res.data;
                    this.selectedFunction = (this.casinoFunctions.filter(obj => obj.id === this.casinoSettings.playerLevelFunctionId))[0];
                    this.timeReference = this.casinoSettings.playerLevelHours;
                });
        },

        getTimePeriods(){
            axios.get('timePeriods')
                .then(res=>{
                    this.timePeriods = res.data;
                });
        },

        getPlayerResponsibilityLevelFeatureTypes(){
            axios.get('casinoPlayerResponsibilityLevelFeatureTypes')
                .then(res=>{
                    this.responsabilityFeatureTypes = res.data;
                });
        },

        putTimeReference(){
            this.loading = true;
            
            if(this.timeReference<0){
                Vue.toasted.error('Value cannot be negative'); 
                this.loading = false;
            } else {
                let obj = {
                    id:this.casinoSettings.id,
                    playerLevelHours: this.timeReference
                };
                axios.put('casinoSettings/'+ this.casinoSettings.id+'/playerLevelHours',obj)
                    .then(res=> {
                        Vue.toasted.success('Time reference updated.');
                        this.timeReference='';
                        this.getCasinoSettings();        
                        this.loading = false;
                    })
                    .catch(err => {

                        if(err.response.data.title) {
                            Vue.toasted.error(err.response.data.title); 
                        } else {
                            Vue.toasted.error('There was an error processing your request'); 
                        }

                        this.loading = false;

                    });
            }
           
            
        },
        
        putCasinoMasterFunction(item){
            this.loading = true;
            let obj = {
                id:this.casinoSettings.id,
                playerLevelFunctionId: item.id
            };
            axios.put('casinoSettings/'+ this.casinoSettings.id+'/playerLevelFunction',obj)
                .then(res=> {
                    Vue.toasted.success('Master Function updated.');
                    this.getCasinoSettings();        
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },

        setVisibility(item){
            item.isVisible = !item.isVisible;
            this.$forceUpdate();
        },

        putVisibility(item){
            this.loading = true;
            let obj = JSON.parse(JSON.stringify(item));
            obj.isVisible = !obj.isVisible;
            delete obj.type;

            axios.put('casinoPlayerLevelFeatures/'+item.id,obj)
                .then(res=> {
                    Vue.toasted.success('Item updated.');
                    this.getCasinoPlayerLevels();
                    this.getCasinoPlayerLevels(this.levelFeatureData[0]);
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        addNewRuleRow(){
            this.temp.rules.push(
                {
                    functionId: null,
                    periodId: null,
                    value: null
                }
            );
            this.$forceUpdate();
        },

        removeRuleRow(item, index){
            if(item.id){
                (this.temp.rules[index].status == 'Active') ? this.temp.rules[index].status = 'Deleted' : this.temp.rules[index].status = 'Active';
                console.log(this.temp.rules[index].status);
            } else {
                this.temp.rules.splice(index,1);
                this.$forceUpdate();
            }
            
        },

        addNewActionRow(){
            this.temp.features.push(
                {
                    typeId: null,
                    stringValue: null,
                    numericValue: null,
                    um: null
                }
            );
            this.$forceUpdate();
        },

        removeActionRow(item, index){
            if(item.id){
                (this.temp.features[index].status == 'Active') ? this.temp.features[index].status = 'Deleted' : this.temp.features[index].status = 'Active';
                console.log(this.temp.features[index].status);
            } else {
                this.temp.features.splice(index,1);
                this.$forceUpdate();
            }
        },

        forceRender(){
            this.$nextTick(()=>{
                this.$forceUpdate();
            });
        },

		openAddNewModal(){
            this.editing = true;
            this.temp.features = [];
            this.featureTypes.forEach(el => this.temp.features.push({typeId:el.id, value: null, isVisible:false}));
            this.temp.casinoId = this.$route.params.casino_id;
        },

        openEditModal(item){
            this.editing = true;
            this.temp = JSON.parse(JSON.stringify(item));
        },

        openDeleteModal(selected){
            this.selected = selected;
            this.deleting = true;
        },

        openCreateRGModal(){
            this.editing = true;
            this.gaming = true;
            this.temp.rules = [];
            this.temp.features = [];
            this.temp.rules.push(
                {
                    functionId: null,
                    periodId: null,
                    value: null
                }
            );
            this.temp.features.push(
                {
                    typeId: null,
                    stringValue: null,
                    numericValue: null,
                    um: null
                }
            );
        },

        openEditRGModal(item){
            this.editing = true;
            this.gaming = true;
            this.temp = JSON.parse(JSON.stringify(item));
        },

        openDeleteRGModal(selected){
            this.selected = selected;
            this.deleting = true;
            this.gaming = true;
        },

		validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);
                    let fieldValid
                    if(Array.isArray(this.$refs[field.ref])){
                        fieldValid = this.$refs[field.ref][0].validate(true);  
                    } else {
                        fieldValid = this.$refs[field.ref].validate(true);
                    }
   
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        createItem(item, keepOpen){
            this.loading = true;
            
            if(!this.validate(this.fields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            } 
            
            //console.log(item);
            axios.post('casinoPlayerLevels', item)
                .then(res=> {
                    Vue.toasted.success('Item created.');
                    this.getCasinoPlayerLevels();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        updateItem(item){
            this.loading = true;
            let cleanedItem = JSON.parse(JSON.stringify(item));
            if(!this.validate(this.fields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            } 
            
            cleanedItem.features = cleanedItem.features.map(obj=>{
                let rObj = obj;
                delete rObj.type;
                return rObj;
            });
            console.log(cleanedItem);

            axios.put('casinoPlayerLevels/'+item.id, cleanedItem)
                .then(res=> {
                    Vue.toasted.success('Item updated.');
                    this.getCasinoPlayerLevels(res.data);
                    this.closeModals();
                    
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        deleteItem(item) {
            this.isLevelSelected=false;
            if(item.id === this.levelFeatureData[0].id){
                this.levelFeatureData = null;
            }
            //console.log(item);
            axios.delete('casinoPlayerLevels/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getCasinoPlayerLevels();
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });

        },

        createGamingRule(item){
            this.loading = true;
            let tempRefKeys = Object.keys(this.$refs);
            let emptyRefs = []

            tempRefKeys.forEach(item=>{
                if(Array.isArray(this.$refs[item])){
                    if(this.$refs[item].length === 0){
                        emptyRefs.push(item);
                    }
                }
            });
            let refKeys = tempRefKeys.filter(x=>!emptyRefs.includes(x))
            refKeys.forEach(ref=>{
                this.ruleFields.push({ref:ref,required:true});
            });
             if(!this.validate(this.ruleFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            } 
            item.playerLevelId = this.levelFeatureData[0].id;
            item.features.forEach(feature =>{
                if(feature.typeId === 3){
                    feature.um = 's';
                    delete feature.stringValue;
                } else {
                    delete feature.numericValue;
                    delete feature.um;
                }
            });
            console.log(item);
            axios.post('casinoPlayerResponsibilityLevels', item)
                .then(res=>{
                    Vue.toasted.success('Item created.');
                    this.getCasinoPlayerLevels(this.levelFeatureData[0]);
                    this.getLevelFeatures(this.levelFeatureData[0]);
                    this.closeModals();
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        updateGamingRule(item){
            this.loading = true;
            item.casinoId = this.$route.params.casino_id;
            let tempRefKeys = Object.keys(this.$refs);
            let emptyRefs = []

            tempRefKeys.forEach(item=>{
                if(Array.isArray(this.$refs[item])){
                    if(this.$refs[item].length === 0){
                        emptyRefs.push(item);
                    }
                }
            });
            let refKeys = tempRefKeys.filter(x=>!emptyRefs.includes(x))
            refKeys.forEach(ref=>{
                this.ruleFields.push({ref:ref,required:true});
            });
             if(!this.validate(this.ruleFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            } 
            
            console.log(item);
            item.features.forEach(element=>{
                delete element.type;
            });
            item.rules.forEach(element=>{
                delete element.function;
                delete element.period;
            });

            axios.put('casinoPlayerResponsibilityLevels/'+item.id, item)
                .then(res=> {
                    Vue.toasted.success('Item updated.');
                    this.getCasinoPlayerLevels(this.levelFeatureData[0]);
                    this.getLevelFeatures(this.levelFeatureData[0]);
                    this.closeModals();
                    
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });
        },

        deleteGamingRule(item){
            axios.delete('casinoPlayerResponsibilityLevels/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.getCasinoPlayerLevels(this.levelFeatureData[0]);
                    this.getLevelFeatures(this.levelFeatureData[0]);
                    this.closeModals();
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });
        },

		closeModals() {
            this.temp = {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
            this.gaming = false;
            this.ruleFields=[];
        },
	}
}
</script>
<template>
	<div class="tab egms">
		
					<div class="card card-purple" v-if="data"  data-aos="fade" style="margin-top: 20px;">
						<crud-zone 
						label="Electronic Gaming Machines" 
						endpoint="egMachines/"
						@refresh="getEgms"
						:fields="fields" 
						:data="data" 
						:canEdit="true" 
						:canAdd="true" 
						:canDelete="true" /> 		
					</div>			
				
	</div> 
</template>
<script>
import CrudZone from '@/components/CrudZone.vue';
export default {
	components: {
		'crud-zone': CrudZone
	},
	data(){
		return {
			data: null,
			fields: [
				{
					label: 'Manufacturer',
					getValue: item => this.$route.params.manufacturer_id,
					type: 'hidden',
					key: 'manufacturerId',
				},
				{
					label: 'Serial No',
					getValue: item => item.serial,
					getLink: item => '/panel/admin/egm/' + item.id, 
					type: 'text',
					key: 'serial',
					required: true,
				},
				{
					label: 'Casino',
					getValue: item => (item.casino) ? item.casino.name : item.casinoId,
					getEndpoint: item => 'backend/casinos', 
					type: 'select-db',
					key: 'casinoId',
					required: true,
				},
				{
					label: 'Model',
					getValue: item => (item.model) ? item.model.name : null,
					getEndpoint: item => 'system/manufacturers/' + this.$route.params.manufacturer_id + '/cabinets', 
					type: 'select-db',
					key: 'modelId',
					required: true,
				},
				{
					label: 'Game Mix',
					getValue: item => (item.gameMix) ? item.gameMix.name : null,
					getEndpoint: item => 'system/manufacturers/' + this.$route.params.manufacturer_id + '/gameMixes', 
					type: 'select-db',
					key: 'gameMixId',
					required: true,
				},
				
				{
					label: 'Denomination',
					getValue: item => item.denominationInMoney,
					type: 'text',
					key: 'denominationInMoney',
					required: true,
				},
				{
					label: 'RTP (%)',
					getValue: item => item.rtp,
					type: 'text',
					key: 'rtp',
					required: false,
				},
				{
					label: 'Machine No',
					getValue: item => item.number,
					type: 'text',
					key: 'number',
					required: true,
				},
				{
					label: 'Software Version',
					getValue: item => item.eepromVersion,
					type: 'text',
					key: 'eepromVersion',
					required: false,
				}, 
			],
		}
	},
	mounted(){
		this.getEgms();
	},
	methods: {

		getEgms(){

			axios.get('admin/manufacturers/' + this.$route.params.manufacturer_id + '/egms') 
				 .then(res => {
				 	this.data = res.data;
				 });
		}

	}
}
</script>
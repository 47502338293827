<template>
    <div class='descriptor_types'>
        <p class="desc_type_title">{{gameType.name}}</p>
        <div v-if='data' data-aos='fade'>
            <v-btn-toggle multiple group color='green' v-model='typeIndex'>
                <custom-button v-for='el in data' :key='el.id' :typeValue='el' @typeValue='emittedValue'></custom-button>
            </v-btn-toggle> 
        </div>
    </div>
</template>
<script>
import CustomDescBtn from '@/components/CustomDescBtn'
export default {
    components:{
        'custom-button' :CustomDescBtn
    },
    props:['gameType','isEdited','activeDesc'],

    data(){
        return {
            data:null,
            typeIndex:[],
            valuesByType: {},
            emitObject: null
        } 
    },

    mounted(){
        this.getGameDescriptorTypeValues();
       
    },

    methods:{
        getGameDescriptorTypeValues(){
            axios.get('gameDescriptorTypes/'+this.gameType.id+'/values')
                .then(res => {
                    this.data = res.data;
                    this.activeValuesOnEdit();

                });
        },
        emittedValue(item){
            this.valuesByType[item.id] = item;
            let x = [];
            // if(x.length ===0){
            //     x.push({typeId:this.gameType.id})
            // }
            
            for(const key in this.valuesByType){
                if(this.valuesByType.hasOwnProperty(key)){
                    if(this.valuesByType[key].data !== null){
                       x.push({
                                gameDescriptorTypeId :  this.gameType.id,
                                gameDescriptorValueId : key
                            })
                    }
                }
            }

            this.emitObject = x;
            this.$emit('descriptorTypeValues', this.emitObject);

        },
        activeValuesOnEdit(){
            if(this.isEdited){
                this.activeDesc.forEach(el => {
                    for(const key in el){
                        if(el.hasOwnProperty(key)){
                            if(key == this.gameType.id){
                                let itemIndex = this.data.findIndex(elem=> elem.id == el[key].gameDescriptorValueId);
                                this.typeIndex.push(itemIndex);
                            }
                        }
                    }
                });
            }
        }
    }
}
</script>
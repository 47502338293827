<template>
    <div class="page manufacturers">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if="data" data-aos="fade">
                            <div class="title">{{data.manufacturer.name}}</div>
                            <div class="subtitle">Overview</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                            <v-btn :elevation="0" class="print_btn float-right"><v-icon>mdi-printer</v-icon> Print</v-btn>
                            <v-btn :elevation="0" class="red_btn float-right">Lock</v-btn>
                        </div>
                        
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if="data" data-aos="fade">
					<v-col cols='12'>
                        <v-slide-group 
                        show-arrows
                        >
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card :elevation="0" class="nav_card_basic">
                                    <p>{{item.label}}</p>
                                    <p>{{item.value}}</p>	
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
					</v-col>
				</v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='3' lg='3' xl='3' class="side_bar">
                        <span class="side_bar_info">Key Info</span>

                        <div class="card card-blue" v-if="data" data-aos="fade">                            
                            <div class="card_header sys_info">
                                <span>SysInfo</span>
                            </div>
                            <div class="table_container">
                                <vertical-table :sysInfo='sysInfoFields'/>
                            </div>
                        </div>
                    </v-col>

                    <v-col xs='12' sm='12' md='9' lg='9' xl='9'>
                        <div class="navigation navigation-purple">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content">
                            <router-view @refreshStats='getManufacturerData'></router-view>     
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import VerticalTable from '@/components/tables/VerticalTable.vue'

export default {
    components:{
        'tab-nav':TabNav,
        'vertical-table': VerticalTable,
    },
    data(){
        return {
            data:null,
            tabNames: [
                {
                    label:'Cabinets',
                    route:'cabinet'
                },
                {
                    label:'Games',
                    route:'game'
                },
                {
                    label:'Game Mix',
                    route:'mix'
                },
                {
                    label:'Machines',
                    route:'machine'
                }
            ],
        }
    },
    computed:{
        cardInfo: function(){
            return [
                {
                    label:'Cabinets',
                    value: this.data.numberOfCabinets
                },
                {
                    label:'Games',
                    value: this.data.numberOfGames
                },
                {
                    label: 'Game Mixes',
                    value: this.data.numberOfGameMixes
                },
                {
                    label: 'EGMs',
                    value: this.data.numberOfEGMs
                },
            ]
        },
        sysInfoFields: function(){
            return [
                {
                    label:'Name:',
                    value: this.data.manufacturer.name
                },
                {
                    label:'Key color:',
                    value: this.data.manufacturer.color
                },
                {
                    label:'Code:',
                    value: this.data.manufacturer.status
                },
            ]
        },
    },
    mounted(){
        this.getManufacturerData();
    },
    methods: {
        getManufacturerData(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id)
                .then(res=>{
                    this.data = res.data;
                });
        }
    }
}
</script>
<template>
<div class="page hardware">
	<div class="header-narrow">
		<v-container>
			<div class="entity" data-aos="fade">
						<div class="title">Hardware</div>
						<div class="subtitle">Equipment details</div>
					</div>
		</v-container>
	</div>
	<div class="navigation navigation-blue">
		<v-container>
			<v-row>
				<v-col cols="12">
					<tab-nav :tabNames="tabNames"></tab-nav>
				</v-col>
			</v-row>
		</v-container>
	</div>
	<div class="content">
		<router-view></router-view>
	</div>
</div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
export default {
	components:{
		'tab-nav':TabNav
	},
	data(){
		return {
			data:null,
			tabNames:[
				{
					label:'Bootloader',
					route: 'bootloader'
				},
				{
					label:'Bill Acceptors',
					route:'billAcceptor'
				},
				{
					label:'Touch Controllers',
					route:'touchController'
				}
			]
		}
	},
	mounted() {
		
	},
	methods: {

	}
}
</script>
<template>
  <div class="tab touchController">
    <div class="container">
      <v-row>
        <v-col xs='12' sm='12' md='12' lg='7' xl='8'>
          <div class="card card-blue" v-if="data" data-aos="fade">
            <div class="card_header">
              <span class="float-left">Touch Controller Manager</span>
              <span class="float-right text-blue">
                  <add-new-item
                    :label='addBtnLabel'
                  @openSpecificCanAdd='openAddTouch'
                  />
              </span>
              <div class='pageSizeMenu_container float-right'>
                <v-menu content-class="pageMenu" offset-y left max-width='190' data-aos="fade">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="white" fab x-small dark v-bind="attrs" v-on="on" :elevation='0'>
                            <span class="sort">{{page.size}}</span>
                            <v-icon class="menu_icon">mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in pageSizes" :key="index" v-ripple @click="setSort(item)">
                            <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div class='float-right'>Items per page:</div>
            </div>
            <crud-table-select class="gen_table" :key='1' :data='data.data' :fields='touchFields' :canEdit="true" :canDelete="true" :canSelect='false'  @edit="openEditModal" @delete="openDeleteModal"></crud-table-select>
            <div class="text-center table-pagination">
              <v-pagination
              v-model="page.index"
              :length="numberOfPages"
              :total-visible="6"
              color='#1976d2'
              @input='getTouchControllers(page)'
              ></v-pagination>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="600">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Touch Controller</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class='cabinet_modal'>
             
                        <!-- Add/Edit Touch Controller -->
                        
                        <v-row justify='center'> 
                            <v-col cols='8' >
                                <form>
                                    <br>
                                    <div class="fields">
                                        <div class="field" >
                                          <v-text-field 
                                            label="Serial"
                                            v-model="temp.serial"
                                            :rules="[() => !!temp.serial || 'This field is required']"
                                            ref='serial'
                                            outlined
                                          />
                                          <v-select
                                            label='Manufacturer'
                                            :items="deviceManufacturers"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.manufacturerId"
                                            :rules="[() => !!temp.manufacturerId || 'This field is required']"
                                            ref='manufacturer'
                                            outlined
                                          />
                                          <v-select
                                            label='Protocol'
                                            :items="deviceProtocols"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.protocolId"
                                            :rules="[() => !!temp.protocolId || 'This field is required']"
                                            ref='protocol'
                                            outlined
                                          />
                                          <v-select
                                            label='Video Stream Resolution'
                                            :items="videoResolutions"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.touchSettings.videoResolutionId"
                                            :rules="[() => !!temp.touchSettings.videoResolutionId || 'This field is required']"
                                            ref='videoResolution'
                                            outlined
                                          >
                                            <template v-slot:selection='{item}'>{{item.name}} - {{item.width}}x{{item.height}}</template>
                                            <template v-slot:item='{item}'>{{item.name}} - {{item.width}}x{{item.height}}</template>
                                          </v-select>
                                          <v-checkbox 
                                            label="Enabled" 
                                            v-model="temp.touchSettings.isEnabled"
                                            :rules="[() => !!temp.touchSettings.isEnabled || 'This field is required']"
                                            ref='isEnabled'
                                            style='margin-bottom:8px; margin-top: 0px'
                                          />
                                        </div>
                                    </div>
                                </form>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>

                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>

                        
                        <v-btn class="modal_btn"  :elevation="0" v-if='!temp.id' color="green" dark @click="createTouch(temp)">
                            Add New
                        </v-btn>
                        <v-btn class="modal_btn"  :elevation="0" v-if='temp.id' color="green" dark @click="updateTouch(temp)">
                            Save
                        </v-btn>
                       
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Touch Controller</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteTouch(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
  </div>
</template>

<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTableSelect from '@/components/tables/CrudTableSelect.vue';
import CrudTable from '@/components/tables/CrudTable.vue';
export default {
  components: {
        'add-new-item': AddNewItem,
        'crud-table-select':CrudTableSelect,
        'crud-table':CrudTable,
    },
    data() {
      return {
        data:null, 
        deviceProtocols:null,
        deviceManufacturers:null,
        videoResolutions:null,
        touchFields:[
          {
            label: 'Manufacturer',
            getValue: item => item.manufacturer.name,
            type: 'text',
            key:'manufacturer',
            ref: 'manufacturer',
            required:true,
          },
          {
            label: 'Serial',
            getValue: item => item.serial,
            type: 'text',
            key:'serial',
            ref: 'serial',
            required:true,
          },
          {
            label: 'Protocol',
            getValue: item => item.protocol.name,
            type: 'text',
            key:'protocol',
            ref: 'protocol',
            required:true,
          },
          {
            label: 'Resolution X',
            getValue: item => item.touchSettings.videoResolution.width,
            type: 'text',
            key:'resolutionX',
            ref: 'resolutionX',
            required:false,
          },
          {
            label: 'Resolution Y',
            getValue: item => item.touchSettings.videoResolution.height,
            type: 'text',
            key:'resolutionY',
            ref: 'resolutionY',
            required:false,
          },
          {
            label: 'Video Resolution',
            key: 'videoResolution',
            ref: 'videoResolution',
            type: 'hidden',
            required:true
          },
          {
            label: 'Enabled',
            key: 'isEnabled',
            ref: 'isEnabled',
            type: 'hidden',
            required:true
          }
        ],
        temp:{},

        editing: false,
        deleting: false,
        selected:null,
        loading: false,
       
        pageLoading:false,
        pageSizes:[10,20,50],
        initialPage: {
          size:10,
          index:1
        },
        page: {
          size:10,
          index:1
        },

        addBtnLabel:'Add New',

      }
    },
    mounted(){
      this.getTouchControllers(this.initialPage);
      this.getProtocols();
      this.getDeviceManufacturers();
      this.getVideoStreamResolutions();
    },
    computed: {
      numberOfPages: function(){
        return Math.ceil(this.data.count/this.page.size)
      },
    },
    methods: {
      getTouchControllers(item){
        axios.get('infrastructure/touchControllers?pageIndex='+(item.index-1)+'&pageSize='+item.size)
            .then(res=>{
                this.data = res.data;
            })
      },
      setSort(item){
        this.page.index = 1;
        this.page.size = item;
        this.getBootloaders(this.page);
      },
      getProtocols(){
        axios.get('deviceProtocols?typeId=5')
          .then(res=>{
            this.deviceProtocols = res.data;
          })
      },
      getDeviceManufacturers(){
        axios.get('deviceManufacturers?typeId=5')
          .then(res=>{
            this.deviceManufacturers = res.data;
          })
      },
      getVideoStreamResolutions(){
        axios.get('videoStreamResolutions')
          .then(res=>{
            this.videoResolutions=res.data;
          })
      },
      validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

          return valid;
      },
      createTouch(item){
        this.loading = true;
        item.casinoId = null;
        item.typeId = 5; /* Device Type ID must be of type touch controller*/
        if(!this.validate(this.touchFields)) {
          Vue.toasted.error('Please fill in the form');
          this.loading = false;
          return; 
        }
        
        axios.post('devices', item)
              .then(res=> {
                  Vue.toasted.success('Item created.');
                  this.getTouchControllers(this.initialPage);
                  this.closeModals();
                  this.loading = false;
              })
              .catch(err => {
                  
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error processing your request'); 
                  }

                  this.loading = false;

                });
      },
      
      updateTouch(item){
        this.loading = true;            
        if(!this.validate(this.touchFields)) {
            Vue.toasted.error('Please fill in the form');
            this.loading = false;
            return; 
        }
        
        axios.put('devices/'+item.id, item)
              .then(res => {

                Vue.toasted.success('Item updated.');
                this.getTouchControllers(this.initialPage);
                this.closeModals();
                                        
                this.loading = false;
                
              })
              .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

              });
      },
      deleteTouch(item){
        axios.delete('devices/' + item.id)
              .then(res => {
                  Vue.toasted.success('Item deleted.');
                  this.closeModals();
                  this.getTouchControllers(this.initialPage);
              })
              .catch(err => {
                  if(err.response.data.title) {
                      Vue.toasted.error(err.response.data.title); 
                  } else {
                      Vue.toasted.error('There was an error deleting this item.')
                  }
              });
      },
      

      openAddTouch(){
        this.editing = true;     
        this.temp.touchSettings = {};
        this.temp.touchSettings.isEnabled = false;    
      },
      openEditModal(item){ 
        this.editing = true;
        let obj = JSON.parse(JSON.stringify(item));

        delete obj.manufacturer;
        delete obj.protocol;
        delete obj.type;
        delete obj.videoStreams;
        delete obj.billAcceptorChannels;
        delete obj.touchSettings.videoResolution;

        this.temp = obj;
      },
      openDeleteModal(selected){
        this.selected = selected;
        this.deleting = true;
      },
      closeModals() {
        this.temp = {};
        this.selected = null;
        this.editing = false;
        this.deleting = false;
      },
    }
}
</script>


<template>
    <div class="cabinet eventManage">
        <div style='margin-top:20px'>
			<v-row>
                <v-col xs='12' sm='12' md='12' lg='5' xl='4'>
                    <div class="card card-purple" data-aos="fade">
                            <div class="card_header">
                            <span class="float-left">Events</span>
                            <span class="float-right text-purple">
                                <add-new-item
                                 :label='addBtnLabel'
                                @openSpecificCanAdd='openAddEvent'
                                />
                            </span>
                        </div>
                        <crud-table-select class="gen_table" :key='1' :data='data' :fields='fields' :canEdit="false" :canDelete="true" :canSelect='true'  @delete="openDeleteModal" @selectItem="getEventsActions"></crud-table-select>       
                    </div>  
                </v-col>

                <v-col xs='12' sm='12' md='12' lg='4' xl='4'>
                    <div :class="{'card-grayed': !eventSelected}" class="card card-purple">
                        <div class="card_header">
                            <span class="float-left">Action</span>
                            <span class="float-right text-purple">
                                <add-new-item
                                 :label='addBtnLabel'
                                @openSpecificCanAdd='openAddAction'
                                />
                            </span>
                        </div>
                        <crud-table-select  v-if='actionData' data-aos="fade" class="gen_table" :key='2' :data='actionData' :fields='actionFields' :canEdit="false" :canDelete="true" :canSelect='true' :reset='reset' @delete="openDeleteModal" @selectItem="getActionTypesEvents"></crud-table-select>       
                    </div>  
                </v-col>

                <v-col xs='12' sm='12' md='12' lg='3' xl='3'>
                    <div :class="{'card-grayed': !actionSelected}" class="card card-purple">
                        <div class="card_header">
                            <span class="float-left">Events with action</span>
                            <span></span>
                        </div>
                        <crud-table-select v-if='actionTypeData' data-aos="fade" class="gen_table" :key='3' :data='actionTypeData' :fields='actionTypesFields' :canEdit="false" :canDelete="false" ></crud-table-select>       
                    </div>  
                </v-col>
            </v-row>	
		</div>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="600">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p v-if='event'>Event</p>
                        <p v-if='!event'>Action</p>
                        <p>Add</p>
                    </div>
                    
                    <v-card-text class='cabinet_modal'>
             
                        <!-- Add Event to Cabinet -->
                        
                        <v-row justify='center' v-if='event'> 
                            <v-col cols='8' >
                                <form>
                                    <br>
                                    <div class="fields">
                                        <div class="field">
                                            <v-select
                                            label='Event'
                                            :items="eventsList"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.EventId"
                                            :rules="[() => !!temp.EventId || 'This field is required']"
                                            ref='event'
                                            outlined
                                            />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>

                        <!-- Add Action to Event -->

                        <v-row justify='center' v-if='!event'> 
                            <v-col cols='8' >
                                <form>
                                    <br>
                                    <div class="fields">
                                        <div class="field">
                                            <v-select
                                            label='Action'
                                            :items="actionsList"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.EventActionId"
                                            :rules="[() => !!temp.EventActionId || 'This field is required']"
                                            ref='action'
                                            outlined
                                            />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>

                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>

                        <!-- Buttons for Add Event -->
                        <v-btn class="modal_btn"  :elevation="0" v-if='event' color="green" dark @click="addEvent(temp)">
                            Save
                        </v-btn>
                        
                        <!-- Buttons for Add Action -->
                        <v-btn class="modal_btn"  :elevation="0" v-if='!event' color="green" dark @click="addAction(temp)">
                            Save
                        </v-btn>
                       
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Cabinet</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" v-if='event' color="red" dark @click="deleteEvent(selected)">
                            Delete
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" v-if='!event' color="red" dark @click="deleteAction(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
import AddNewItem from '@/components/crud/AddNewItem.vue';
import CrudTableSelect from '@/components/tables/CrudTableSelect.vue';
export default {
    components: {
        'add-new-item': AddNewItem,
        'crud-table-select':CrudTableSelect,
    },
    data() {
       return {
            data:null,
            actionData:null,
            actionTypeData:null,

            eventsList:null,
            actionsList:null,

            temp: {},

            selectedEventItem:null,
            eventSelected:false,
            actionSelected:false,
            reset: null,

            loading:false,
            event: false,
            editing:false,
            deleting: false,

            fields:[
                {
                    label: 'Type',
                    getValue: item => item.event.type.name,
                    type: 'text',
                    key:'type',
                    ref: 'type',
                    required:true,
                },
                {
                    label: 'Name',
                    getValue: item => item.event.name,
                    type: 'text',
                    key:'name',
                    ref: 'name',
                    required:true,
                },
                {
                    label: 'Remote',
                    getValue: item => item.event.status,
                    type: 'text',
                    key:'remote',
                    ref: 'remote',
                    required:false,
                }
            ],
            actionFields:[
                {
                    label: 'Action Name',
                    getValue: item => item.eventAction.name,
                    type: 'text',
                    key:'actionName',
                    ref: 'actionName',
                    required:true,
                },
                {
                    label: 'Parameter',
                    getValue: item => item.eventAction.type.name,
                    type: 'text',
                    key:'parameter',
                    ref: 'parameter',
                    required:true,
                }
            ],
            actionTypesFields:[
                {
                    label: 'Type',
                    getValue: item => item.event.type.name,
                    type: 'text',
                    key:'type',
                    ref: 'type',
                    required:true,
                },
                {
                    label: 'Name',
                    getValue: item => item.event.name,
                    type: 'text',
                    key:'name',
                    ref: 'name',
                    required:true,
                },
                {
                    label: 'Remote',
                    getValue: item => item.event.status,
                    type: 'text',
                    key:'remote',
                    ref: 'remote',
                    required:false,
                }
            ],

            addBtnLabel:'Add New',


       } 
    },
    mounted(){
        this.getCabinetEvents();
        this.getEventsList();
        this.getActionsList();
        this.actionData = this.emptyData;
        this.actionTypeData = this.emptyData;
    },
    
    methods: {

        //API Data calls
        getCabinetEvents(){
            axios.get('system/cabinets/'+this.$route.params.cabinet_id+'/events')
                .then(res=>{
                    this.data = res.data;
                    
                });
        },

        getEventsActions(item){
            axios.get('system/events/'+item.eventId+'/actions')
                .then(res=>{
                    this.actionData = res.data;
                    this.selectedEventItem = item;

                    if(this.eventSelected && this.actionSelected){
                        this.reset = 0;
                    }
                    if(this.eventSelected && !this.actionSelected){
                        this.reset = null;
                    }
                    this.eventSelected = true;
                    this.actionSelected = false;
                    this.actionTypeData = null;

                });
            
        },

        getActionTypesEvents(item){
            console.log(item);
            
            axios.get('system/actionTypes/'+item.eventActionId+'/events')
                .then(res=>{
                    this.actionTypeData = res.data;
                    this.actionSelected = true;
                    this.reset = null;
                    
                });
        },

        getEventsList(){
            axios.get('/events')
                .then(res=>{
                    this.eventsList = res.data
                });
        },

        getActionsList(){
            axios.get('/eventActions')
                .then(res=>{
                    this.actionsList = res.data
                });
        },

        validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        //Open Modals
        openAddEvent(){
            this.editing = true;      
            this.event = true     
        },

        openAddAction(){
            this.editing = true; 
            this.event = false;
        },

        openDeleteModal(selected){
            this.selected = selected;
            this.deleting = true;
        },

        closeModals() {
            this.temp = {};
            
            this.selected = null;
            this.editing = false;
            this.deleting = false;
        },


        //CRUD Operations
        addEvent(item){
            
            item.EGMModelId = this.$route.params.cabinet_id;
            //item.EGMModelId = 1;
            console.log(item);

            axios.post('egmModelEvents', item)
                .then(res=> {
                    Vue.toasted.success('Event added.');
                    this.closeModals();
                    this.getCabinetEvents();
                })
                .catch(err => {
                    Vue.toasted.error('There was an error adding this item.')
                });
        },

        addAction(item){
            item.EGMModelEventId = this.selectedEventItem.eventId;
            console.log(item);
            
            axios.post('egmModelEventActions', item)
                .then(res=> {
                    Vue.toasted.success('Action added.');
                    this.closeModals();
                    this.getEventsActions(this.selectedEventItem);
                })
                .catch(err => {
                    Vue.toasted.error('There was an error adding this item.')
                });
        },

        deleteEvent(item) {
            //console.log(item);
            axios.delete('egmModelEvents/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getCabinetEvents();
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });
        },

        deleteAction(item) {
            console.log(item);
            axios.delete('egmModelEventActions/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getEventsActions(this.selectedEventItem);
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });
        },
    }
}
</script>
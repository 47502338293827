<template>
    <div class="page cabinet">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if='cabinet' data-aos="fade">
                            <div class="title">{{cabinet[0].name}}</div>
                            <div class="subtitle">Detail Management</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                            <v-btn :elevation="0" class="print_btn float-right"><v-icon>mdi-printer</v-icon> Print</v-btn>
                            <v-btn :elevation="0" class="red_btn float-right">Lock</v-btn>
                        </div>
                        
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if='data' data-aos="fade">
					<v-col cols='12'>
                        <v-slide-group 
                        show-arrows
                        >
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card :elevation="0" class="nav_card_basic">
                                    <p>{{item.label}}</p>
                                    <p>{{item.value}}</p>	
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
					</v-col>
				</v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='4' lg='3' xl='2' class="side_bar">
                        
                        <span class="side_bar_info">Key Info</span>

                        <div class="card card-blue" v-if="data" data-aos="fade">                            
                            <div class="card_header sys_info">
                                <v-row no-gutters>
                                    <v-col cols='12'>
                                         <span>SysInfo</span>
                                    </v-col>
                                    <!-- <v-col cols='4'>
                                        <a class="float-right" @click="openEditModal(cabinet[0])">
                                            <v-icon color="gray">mdi-pencil-outline</v-icon>
                                        </a> 
                                    </v-col> -->
                                </v-row>
                            </div>
                            <div class="table_container">
                                <vertical-table :sysInfo='sysInfoFields'/>
                            </div>
                        </div>

                        <div class="card card-blue" v-if="data" data-aos="fade" style='margin-top:20px'>                            
                            <div class="card_header sys_info">
                                <v-row no-gutters>
                                    <v-col cols='8'>
                                         <span>EGM Info</span>
                                    </v-col>
                                    <v-col cols='4'>
                                        <a class="float-right" @click="openEditModal(cabinet[0])">
                                            <v-icon color="gray">mdi-pencil-outline</v-icon>
                                        </a> 
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="table_container">
                                <vertical-table :sysInfo='cabinetFields' v-if='cabinet'/>
                            </div>
                        </div>
                    </v-col>

                    <v-col xs='12' sm='12' md='8' lg='9' xl='10'>
                        <div class="navigation navigation-purple">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content">
                            <router-view @refreshStats='getManufacturerData' v-if='cabinet'></router-view>     
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="800">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Cabinet</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    
                    <v-card-text class='cabinet_modal'>
             
                        <!-- Edit Cabinet Mix -->
                        
                        <v-row justify='center' v-if='temp.id'> 
                            <v-col cols='6' >
                                <form>
                                    <br>
                                    <div class="fields">
                                        <div class="field">
                                            <v-text-field
                                            label='Name'
                                            v-model="temp.Name"
                                            :rules="[() => !!temp.Name || 'This field is required']"
                                            ref='cabName'
                                            outlined
                                            />
                                            <v-text-field
                                            label='Cabinet Model'
                                            v-model="temp.Model"
                                            :rules="[() => !!temp.Model || 'This field is required']"
                                            ref='cabModel'
                                            outlined
                                            />
                                            <v-select
                                            label='Cabinet Type'
                                            :items="cabinetTypes"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.CabinetTypeId"
                                            :rules="[() => !!temp.CabinetTypeId || 'This field is required']"
                                            ref='cabType'
                                            outlined
                                            />
                                            <v-select
                                            label='Platform'
                                            chips
                                            multiple
                                            :items="cabinetPlatforms"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.Platforms"
                                            :rules="[() => !!temp.Platforms || 'This field is required']"
                                            ref='cabPlatforms'
                                            outlined
                                            />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>

                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        
                        <!-- Buttons for Edit Modal -->
                        <v-btn class="modal_btn"  v-if="temp.id" :elevation="0" color="green" dark @click="updateCabinet(selected,temp)">
                            Save
                        </v-btn>
                       
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <!-- <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Cabinet</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import VerticalTable from '@/components/tables/VerticalTable.vue'

export default {
    components:{
        'tab-nav':TabNav,
        'vertical-table': VerticalTable,
    },
    data(){
        return {
            data:null,
            cabinet:null,
            cabinetTypes:null,
            cabinetPlatforms:null,

            temp:{},
            selected: null,
            editing:false,
            loading:false,

            tabNames: [
                {
                    label:'Pictures',
                    route:'picture'
                },
                {
                    label:'Event Management',
                    route:'eventManage'
                }
            ],
            cabFields:[
                {
                    label:'Name',
                    ref:'cabName',
                    required:true
                },
                {
                    label:'Model',
                    ref:'cabModel',
                    required:true
                },
                {
                    label:'Type',
                    ref:'cabType',
                    required:true
                },
                {
                    label:'Platform',
                    ref:'cabPlatforms',
                    required:true
                }
            ],
        }
    },
    computed:{
        cardInfo: function(){
            return [
                {
                    label:'Cabinets',
                    value: this.data.numberOfCabinets
                },
                {
                    label:'Games',
                    value: this.data.numberOfGames
                },
                {
                    label: 'Game Mixes',
                    value: this.data.numberOfGameMixes
                },
                {
                    label: 'EGMs',
                    value: this.data.numberOfEGMs
                },
            ]
        },
        sysInfoFields: function(){
            return [
                {
                    label:'Name:',
                    value: this.data.manufacturer.name
                },
                {
                    label:'Key color:',
                    value: this.data.manufacturer.color
                },
                {
                    label:'Code:',
                    value: this.data.manufacturer.status
                }
            ]
        },
        cabinetFields: function(){
            return [
                {
                    label: 'Cabinet Model',
                    value: this.cabinet[0].model
                },
                {
                    label: 'Cabinet Type',
                    value: this.cabinet[0].cabinetType.name
                },
                {
                    label: 'Platform',
                    value: this.cabinet[0].platforms
                },
            ]
        },
    },
    mounted(){
        this.getManufacturerData();
        this.getCabinetsByManufacturer();
        this.getCabinetTypes();
        this.getPlatformsByManufacturer();
    },
    methods: {
        getManufacturerData(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id)
                .then(res=>{
                    this.data = res.data;
                });
        },
        getCabinetsByManufacturer(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id+'/cabinets')
                .then(res=>{
                    this.cabinet = res.data.filter(item => item.id == this.$route.params.cabinet_id);
                   
                })
        },
        getCabinetTypes(){
            axios.get('cabinetTypes')
                .then(res=>{
                    this.cabinetTypes = res.data;
                })
        },
        getPlatformsByManufacturer(){
            axios.get('egmManufacturers/'+this.$route.params.manufacturer_id+'/platforms')
                .then(res=>{
                    this.cabinetPlatforms = res.data;
                })
        },

        validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        openEditModal(item){
            this.editing = true;
            this.selected = Object.assign({}, item);
            let platforms = this.selected.platforms.map(obj => {
                let nObj = obj.egmPlatform.id;
                return nObj;
            });
            
            platforms.sort((a,b)=>{
                return a-b;
            })

            this.temp = {
                id:this.selected.id,
                EGMManufacturerId: this.selected.egmManufacturerId,
                egmButtonLayoutId: this.selected.egmButtonLayoutId,
                Name: this.selected.name,
                Model: this.selected.model,
                CabinetTypeId: this.selected.cabinetType.id,
                Platforms: platforms,
                Status: this.selected.status
            }

            // this.temp = Object.assign({}, item);
            // this.temp.cabinetTypeId = this.selected.cabinetType.id;
            // this.temp.platforms = platforms;

        },

        updateCabinet(selected, item){
            this.loading = true;
            let originalValues = [];
            selected.platforms.forEach(el=>{
                originalValues.push(el.egmPlatformId);
            });
                        
            let platforms = item.Platforms.map(obj => {
                let nObj = {}
                nObj['EGMModelId'] = item.id;
                nObj['egmPlatformId'] = obj;
                nObj['Status'] = 'Active';
                return nObj;
            });
            
            selected.platforms.forEach(el=>{
                platforms.forEach(item => {
                    if(el.egmPlatformId === item.egmPlatformId){
                        item.Id = el.id;
                    }
                });
            });

            originalValues.forEach(elem=>{
                if(!item.Platforms.includes(elem)){
                    
                    let deletedPlatforms = selected.platforms.find(el =>el.egmPlatformId === elem);
                    deletedPlatforms.status =  'Deleted';
                    //delete deletedPlatforms.egmPlatform;
                    platforms.push(deletedPlatforms);
                   
                }
            });

            platforms.sort((a,b)=>{
                return a.egmPlatformId - b.egmPlatformId;
            });

            item.Platforms = platforms;



            if(!this.validate(this.cabFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }

            
            
            //console.log(item);

            axios.put('egmModels/'+item.id, item)
            .then(res => {
                Vue.toasted.success('Cabinet updated.');
                this.getCabinetsByManufacturer();
                this.closeModals();
                
                this.loading = false;
            })
            .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

                });
        },

        closeModals() {
            this.temp = {}; 
            this.selected = null;
            this.editing = false;
            this.deleting = false;
           
        },
    }
}
</script>
<template>
    <div class="tab cabinets">
       <div style="margin-top:20px">
			<v-row>
                <v-col cols='12'>
                    <div class="">
                            <v-btn :elevation="0" class="under_nav_btn button_purple float-left" @click="openAddNewModal">Add New<v-icon>mdi-plus</v-icon></v-btn>
                            <v-btn :elevation="0" class="under_nav_btn button_purple float-left">Sort by<v-icon>mdi-menu-down</v-icon></v-btn>
                        </div>
                </v-col>
            </v-row>
		</div>
        <div>
			<v-row>
                <v-col xs='12' sm='12' md='6' lg="4" v-for="item in data" :key="item.id">
                    <div class="card-purple card-border-radius" v-if="data" data-aos="fade">                            
                        <div class="card_image_header">
                            <v-row no-gutters>
                                <v-col cols='10'>
                                    <router-link :to="'/panel/system/manufacturer/'+item.egmManufacturerId+'/cabinet/'+item.id" ><p>{{item.name}}</p></router-link>
                                </v-col>
                                <v-col cols='2'>
                                    <a class="float-right" @click="openDeleteModal(item)">
                                        <v-icon color="gray">mdi-delete-outline</v-icon>
                                    </a>
                                     <a class="float-right" @click="openEditModal(item)">
                                        <v-icon color="gray">mdi-pencil-outline</v-icon>
                                    </a> 
                                </v-col>
                            </v-row>
                            
                        </div>
                        <div class="card_cabinet">
                            <v-row>
                                <v-col cols='4'>
                                    <div class="cabinet_img">
                                        <v-img
                                        height="215"
                                        width="auto"
                                        :src="(item.frontLeftMedia) ? getImage(item.frontLeftMedia.fileName) : '/img/right@2x.png'"
                                        />
                                    </div>
                                    <p class='text-center'>Left</p>
                                </v-col>
                                <v-col cols='4'>
                                    <div class="cabinet_img">
                                        <v-img
                                            height="215"
                                            width="auto"
                                            :src="(item.frontMedia) ? getImage(item.frontMedia.fileName) : '/img/front@2x.png'"
                                        />
                                    </div>
                                    <p class='text-center'>Front</p>
                                </v-col>
                                <v-col cols='4'>
                                    <div class='cabinet_img'>
                                        <v-img
                                            height="215"
                                            width="auto"
                                            :src="(item.frontRightMedia) ? getImage(item.frontRightMedia.fileName) : '/img/left@2x.png'"
                                        />
                                    </div>
                                    <p class='text-center'>Right</p>
                                </v-col>
                                <v-col cols="12 no-padd-top">
                                    <ul class="sys_info">
                                        <li>
                                            <span>Cabinet Model:</span>
                                            <span class="float-right">{{item.model}}</span>
                                        </li>
                                        <li>
                                            <span>Cabinet Type:</span>
                                            <span class="float-right">{{item.cabinetType.name}}</span>
                                        </li>
                                        <li>
                                            <span>Platform:</span>
                                            <div class="cabinet_platforms float-right">
                                                <span v-for='(platform, index) in item.platforms' :key='index'>{{platform.egmPlatform.name}}</span>
                                            </div>  
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>	
		</div>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-model="editing" width="800">
                <v-card v-if='editing'>
                    <div class="modal_header">
                        <p>Cabinet</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                        <span class="modal_step">Step {{step}}/{{noOfSteps}}</span>   
                    </div>
                    <step-pos :step='step' :noOfSteps='noOfSteps'></step-pos>
                    <v-card-text class='cabinet_modal'>

                        <!-- Add New Cabinet -->
                        
                        <v-row justify='center' v-show='!temp.id && step===1'> 
                            <v-col cols='6' >
                                <br>
                                <form>
                                    <div class="fields">
                                        <div class="field">
                                            <v-text-field
                                            label='Name'
                                            v-model="temp.Name"
                                            :rules="[() => !!temp.Name || 'This field is required']"
                                            ref='cabName'
                                            outlined
                                            />
                                            <v-text-field
                                            label='Cabinet Model'
                                            v-model="temp.Model"
                                            :rules="[() => !!temp.Model || 'This field is required']"
                                            ref='cabModel'
                                            outlined
                                            />
                                            <v-select
                                            label='Cabinet Type'
                                            :items="cabinetTypes"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.CabinetTypeId"
                                            :rules="[() => !!temp.CabinetTypeId || 'This field is required']"
                                            ref='cabType'
                                            outlined
                                            />
                                            <v-select
                                            label='Platform'
                                            chips
                                            multiple
                                            :items="cabinetPlatforms"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.Platforms"
                                            :rules="[() => !!temp.Platforms || 'This field is required']"
                                            ref='cabPlatforms'
                                            outlined
                                            />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                        <v-row  v-if='!temp.id && step === noOfSteps'>
                            <v-col cols='3'>
                                <v-btn-toggle
                                    v-model='activeImageInput'
                                    group
                                    color='blue'
                                >
                                    <v-btn class="cabinet_image_btn">L</v-btn>
                                    <v-btn class="cabinet_image_btn">F</v-btn>
                                    <v-btn class="cabinet_image_btn">R</v-btn>
                                </v-btn-toggle>
                                <div v-if="activeImageInput === 0">
                                    <v-img
                                        height="200" 
                                        contain
                                        src='/img/right@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>30&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                                <div v-if="activeImageInput === 1">
                                    <v-img 
                                        height="200"
                                        contain
                                        src='/img/front@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>90&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                                <div v-if="activeImageInput === 2">
                                    <v-img 
                                        height="200"
                                        contain
                                        src='/img/left@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>150&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                            </v-col>
                            <v-col cols='9'>
                                <div v-if='activeImageInput === 0' class="image_input_container">   
                                    <div  class="image_border_background" @click='$refs.fileInput.click()' >
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        :class="[tempImg.leftImg ? 'img_preview' : '']"
                                        height="330"
                                        contain
                                        :src='tempImg.leftImg'/>
                                    </div>
                                    <div v-if='tempImg.leftImg' class='image_overlay'>
                                        <v-btn  :elevation='0' class="remove_preview float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                               
                               <div v-if='activeImageInput === 1' class="image_input_container">
                                   <div  class="image_border_background" @click='$refs.fileInput.click()'>
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event,activeImageInput)'>                               
                                        <img
                                        :class="[tempImg.frontImg ? 'img_preview' : '']"
                                        height="330"
                                        contain
                                        :src='tempImg.frontImg'/>
                                    </div>
                                    <div v-if='tempImg.frontImg' class='image_overlay'>
                                        <v-btn  :elevation='0' class="remove_preview float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                               </div>
                                
                                <div v-if='activeImageInput === 2' class="image_input_container">
                                    <div  class="image_border_background" @click='$refs.fileInput.click()'>
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        :class="[tempImg.rightImg ? 'img_preview' : '']"
                                        height="330"
                                        contain
                                        :src='tempImg.rightImg'/>
                                    </div>
                                    <div v-if='tempImg.rightImg' class='image_overlay'>
                                        <v-btn  :elevation='0' class="remove_preview float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                                
                            </v-col>
                            
                        </v-row>
                        
                        <!-- Edit Game Mix -->
                        
                        <v-row justify='center' v-if='temp.id && step===1'> 
                            <v-col cols='6' >
                                <form>
                                    <br>
                                    <div class="fields">
                                        <div class="field">
                                            <v-text-field
                                            label='Name'
                                            v-model="temp.Name"
                                            :rules="[() => !!temp.Name || 'This field is required']"
                                            ref='cabName'
                                            outlined
                                            />
                                            <v-text-field
                                            label='Cabinet Model'
                                            v-model="temp.Model"
                                            :rules="[() => !!temp.Model || 'This field is required']"
                                            ref='cabModel'
                                            outlined
                                            />
                                            <v-select
                                            label='Cabinet Type'
                                            :items="cabinetTypes"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.CabinetTypeId"
                                            :rules="[() => !!temp.CabinetTypeId || 'This field is required']"
                                            ref='cabType'
                                            outlined
                                            />
                                            <v-select
                                            label='Platform'
                                            chips
                                            multiple
                                            :items="cabinetPlatforms"
                                            item-text='name'
                                            item-value='id'
                                            v-model="temp.Platforms"
                                            :rules="[() => !!temp.Platforms || 'This field is required']"
                                            ref='cabPlatforms'
                                            outlined
                                            />
                                        </div> 
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                        <v-row  v-if='temp.id && step === noOfSteps'>
                            <v-col cols='3'>
                                <v-btn-toggle
                                    v-model='activeImageInput'
                                    group
                                    color='blue'
                                >
                                    <v-btn class="cabinet_image_btn">L</v-btn>
                                    <v-btn class="cabinet_image_btn">F</v-btn>
                                    <v-btn class="cabinet_image_btn">R</v-btn>
                                </v-btn-toggle>
                                <div v-if="activeImageInput === 0">
                                    <v-img
                                        height="200" 
                                        contain
                                        src='/img/right@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>30&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                                <div v-if="activeImageInput === 1">
                                    <v-img 
                                        height="200"
                                        contain
                                        src='/img/front@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>90&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                                <div v-if="activeImageInput === 2">
                                    <v-img 
                                        height="200"
                                        contain
                                        src='/img/left@2x.png'
                                    />
                                    <p class='cabinet_modal_view'>150&deg;</p>
                                    <p class='cabinet_modal_view'>Distance <span>2m</span></p>
                                </div>
                            </v-col>
                            <v-col cols='9'>
                                <div v-if='activeImageInput === 0' class="image_input_container">
                                    <div  class="image_border_background" @click='$refs.fileInput.click()' >
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        v-if='this.selected.frontLeftMedia'
                                        class="img_preview"
                                        height="330"
                                        contain
                                        :src='tempImg.leftImg ? tempImg.leftImg : getImage(this.selected.frontLeftMedia.fileName)'
                                        />
                                        <img
                                        v-if='!this.selected.frontLeftMedia'
                                        :class="[tempImg.leftImg ? 'img_preview' : '']"
                                        height="330"
                                        contain
                                        :src='tempImg.leftImg'
                                        />
                                    </div>
                                    <div  v-if='this.selected.frontLeftMedia' class="image_overlay">
                                        <v-btn :elevation='0' class="float-right" @click='removeImage(selected, activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                        
                                    <div v-if='tempImg.leftImg' class="image_overlay">
                                         <v-btn :elevation='0' class="float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                                
                                <div v-if='activeImageInput === 1' class="image_input_container">
                                    <div  class="image_border_background" @click='$refs.fileInput.click()' >
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        v-if='this.selected.frontMedia'
                                        class="img_preview"
                                        height="330"
                                        contain
                                        :src='tempImg.frontImg ? tempImg.frontImg : getImage(this.selected.frontMedia.fileName)'
                                        />
                                        <img
                                        v-if='!this.selected.frontMedia'
                                        :class="[tempImg.frontImg ? 'img_preview' : '']"
                                        height="330"
                                        contain
                                        :src='tempImg.frontImg'
                                        />
                                    </div>
                                    <div  v-if='this.selected.frontMedia' class="image_overlay">
                                        <v-btn :elevation='0' class="float-right" @click='removeImage(selected, activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                        
                                    <div v-if='tempImg.frontImg' class="image_overlay">
                                         <v-btn :elevation='0' class="float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>

                                <div v-if='activeImageInput === 2' class="image_input_container">
                                    <div  class="image_border_background" @click='$refs.fileInput.click()' >
                                        <input type='file' ref='fileInput' style='display:none' @change='onFileChange($event, activeImageInput)'>
                                        <img
                                        v-if='this.selected.frontRightMedia'
                                        class="img_preview"
                                        height="330"
                                        contain
                                        :src='tempImg.rightImg ? tempImg.rightImg : getImage(this.selected.frontRightMedia.fileName)'
                                        />
                                        <img
                                        v-if='!this.selected.frontRightMedia'
                                        :class="[tempImg.rightImg ? 'img_preview' : '']"
                                        height="330"
                                        contain
                                        :src='tempImg.rightImg'
                                        />
                                    </div>
                                    <div  v-if='this.selected.frontRightMedia' class="image_overlay">
                                        <v-btn :elevation='0' class="float-right" @click='removeImage(selected, activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                        
                                    <div v-if='tempImg.rightImg' class="image_overlay">
                                         <v-btn :elevation='0' class="float-right" @click='removePreview(activeImageInput)'><v-icon>mdi-delete-outline</v-icon></v-btn>
                                    </div>
                                </div>
                            </v-col>
                            
                        </v-row>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>

                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        
                        <!-- Buttons for Add New Modal -->
                        
                        <v-btn class="modal_btn"  v-if="!temp.id && step<noOfSteps" :elevation="0" color="green" dark @click="createCabinet(temp)">
                            Next
                        </v-btn>
                        
                        <v-btn class="modal_btn"  v-if="!temp.id && step===noOfSteps" :elevation="0" color="green" dark @click="addImagesToCabinet(createdItem, tempMedia, false)">
                            Save
                        </v-btn>
                       
                        
                        <!-- Buttons for Edit Modal -->
                        <v-btn class="modal_btn"  v-if="temp.id && step<noOfSteps" :elevation="0" color="green" dark @click="updateCabinet(selected,temp)">
                            Next
                        </v-btn>
                        <v-btn class="modal_btn"  v-if="temp.id && step===noOfSteps" :elevation="0" color="green" dark @click="updateCabinetImages(selected,tempMedia, false)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>Cabinet</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div> 
</template>
<script>
import VerticalTable from '@/components/tables/VerticalTable.vue';
import StepProgress from '@/components/StepProgress.vue';
export default {
    components: {
        'vertical-table':VerticalTable,
        'step-pos': StepProgress 
    },
    data(){
        return {
     
            data: null,
            cabinetTypes: null,
            cabinetPlatforms: null,
           
            editing: false,
            deleting: false,
            selected: null,
            loading: false,

            step: 1, //first step of modal
            noOfSteps: 2, //total steps of modal

            createdItem: null,
            activeImageInput: 0,
           

			temp:{},
            tempImg: {},
            tempMedia: {},
            mediaDim:{},
            cabFields:[
                {
                    label:'Name',
                    ref:'cabName',
                    required:true
                },
                {
                    label:'Model',
                    ref:'cabModel',
                    required:true
                },
                {
                    label:'Type',
                    ref:'cabType',
                    required:true
                },
                {
                    label:'Platform',
                    ref:'cabPlatforms',
                    required:true
                }
            ],
        }
    },
    mounted(){
        this.getCabinetsByManufacturer();
        this.getCabinetTypes();
        this.getPlatformsByManufacturer();
    },
    methods: {
        getCabinetsByManufacturer(){
            axios.get('system/manufacturers/'+this.$route.params.manufacturer_id+'/cabinets')
                .then(res=>{
                    this.data = res.data;
                })
        },

        getCabinetTypes(){
            axios.get('cabinetTypes')
                .then(res=>{
                    this.cabinetTypes = res.data;
                })
        },

        getPlatformsByManufacturer(){
            axios.get('egmManufacturers/'+this.$route.params.manufacturer_id+'/platforms')
                .then(res=>{
                    this.cabinetPlatforms = res.data;
                })
        },

        onFileChange(e, position){
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length){
                return;
            }
            if(position===0){
                this.tempMedia.Left = files[0];
            }
            else if(position===1){
                this.tempMedia.Front = files[0];
            }
            else{
                this.tempMedia.Right = files[0];
            }
           
            this.createImage(files[0], position);
        },

        createImage(file, position){

            var reader = new FileReader();
            var self = this;

            reader.readAsDataURL(file);
            reader.onload = function(e) {
                let image = new Image();

                if(position === 0) {
                    image.src= e.target.result;
                    image.onload = function () {
                        self.mediaDim['HeightLeft'] = this.height;
                        self.mediaDim['WidthLeft'] = this.width;      
                    }
                    self.tempImg.leftImg = e.target.result;
                }

                else if(position === 1) {
                    image.src= e.target.result;
                    image.onload = function () {
                        self.mediaDim['HeightFront'] = this.height;
                        self.mediaDim['WidthFront'] = this.width;
                          
                    }
                    self.tempImg.frontImg = e.target.result;
                }

                else {
                    image.src= e.target.result;
                    image.onload = function () {
                        self.mediaDim['HeightRight'] = this.height;
                        self.mediaDim['WidthRight'] = this.width;    
                    }
                    self.tempImg.rightImg = e.target.result;
                }
                
                self.$forceUpdate();
            };

            this.$refs.fileInput.value = '';
            
        },

        removePreview(position){
            if(position===0){
                this.tempMedia.Left = null;
                this.tempImg.leftImg = null;
            }
            else if(position===1){
                this.tempMedia.Front = null;
                this.tempImg.frontImg = null;
            }
            else{
                this.tempMedia.Right = null;
                this.tempImg.rightImg = null;
            }
            this.$forceUpdate();
        },

        validate(item){

            let valid = true;

            item.forEach(field => {

                // Field is requried
                if(field.required) {

                    //console.log(field);

                    let fieldValid = this.$refs[field.ref].validate(true);  
                    if(!fieldValid) { 
                        valid = false;
                        //console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        openAddNewModal(){
            this.editing = true;
            this.step = 1;
           
        },

        openEditModal(item){
            this.editing = true;
            this.selected = Object.assign({}, item);
            let platforms = this.selected.platforms.map(obj => {
                let nObj = obj.egmPlatform.id;
                return nObj;
            });
            
            platforms.sort((a,b)=>{
                return a-b;
            })

            this.temp = {
                id:this.selected.id,
                EGMManufacturerId: this.selected.egmManufacturerId,
                egmButtonLayoutId: this.selected.egmButtonLayoutId,
                Name: this.selected.name,
                Model: this.selected.model,
                CabinetTypeId: this.selected.cabinetType.id,
                Platforms: platforms,
                Status: this.selected.status
            }

            // this.temp = Object.assign({}, item);
            // this.temp.cabinetTypeId = this.selected.cabinetType.id;
            // this.temp.platforms = platforms;

            this.step = 1;
        },

        openDeleteModal(selected){
            this.selected = selected;
            this.deleting = true;
        },

        closeModals() {
            this.temp = {};
            this.tempMedia = {};
            this.mediaDim = {};
            this.tempImg =  {};
            this.selected = null;
            this.editing = false;
            this.deleting = false;
            this.step = null;
        },

        createCabinet(item){
            this.loading = true;
            item.EGMManufacturerId = this.$route.params.manufacturer_id;
            if(!this.validate(this.cabFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }

            let platforms = item.Platforms.map(obj => {
                let nObj = {}
                nObj['EGMPlatformId'] = obj;
                return nObj;
            });
            
            item.Platforms = platforms;
            this.step+=1;
            

            axios.post('egmModels', item)
            .then(res => {
                this.createdItem = res.data;
                Vue.toasted.success('Cabinet created.');
                this.getCabinetsByManufacturer();

                this.temp={};
                this.loading = false;
                this.$emit('refreshStats');
            })
            .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

                });
        },
        
        addImagesToCabinet(cabinet,media,keepOpen){
             this.loading = true;

            //Validation
            // if(!media.hasOwnProperty('Left') || !media.hasOwnProperty('Front') || !media.hasOwnProperty('Right')){
            //      Vue.toasted.error('Please upload all images');
            //     return;
            // }

            //Create new FormData object
            var formData = new FormData();

            //Setting image dimensions 
            //before passing object to API
            
            for(const key in media){
                if(media.hasOwnProperty(key)){
                    formData.append('File'+key, media[key])
                }
            }
           
            for(const key in this.mediaDim){
                if(this.mediaDim.hasOwnProperty(key)){
                    let value = this.mediaDim[key];
                    formData.append(key, value);
                }
            }


            axios.post('egmModels/'+cabinet.id+'/media', formData, {
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })
                .then(res=>{
                    console.log(res.data);
                    Vue.toasted.success('Item created.');
                    this.getCabinetsByManufacturer();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });

        },

        updateCabinet(selected, item){
            this.loading = true;
            let originalValues = [];
            selected.platforms.forEach(el=>{
                originalValues.push(el.egmPlatformId);
            });
                        
            let platforms = item.Platforms.map(obj => {
                let nObj = {}
                nObj['EGMModelId'] = item.id;
                nObj['egmPlatformId'] = obj;
                nObj['Status'] = 'Active';
                return nObj;
            });
            
            selected.platforms.forEach(el=>{
                platforms.forEach(item => {
                    if(el.egmPlatformId === item.egmPlatformId){
                        item.Id = el.id;
                    }
                });
            });

            originalValues.forEach(elem=>{
                if(!item.Platforms.includes(elem)){
                    
                    let deletedPlatforms = selected.platforms.find(el =>el.egmPlatformId === elem);
                    deletedPlatforms.status =  'Deleted';
                    //delete deletedPlatforms.egmPlatform;
                    platforms.push(deletedPlatforms);
                   
                }
            });

            platforms.sort((a,b)=>{
                return a.egmPlatformId - b.egmPlatformId;
            });

            item.Platforms = platforms;



            if(!this.validate(this.cabFields)) {
               Vue.toasted.error('Please fill in the form');
               this.loading = false;
               return; 
            }

            this.step+=1;
            
            console.log(item);

            axios.put('egmModels/'+item.id, item)
            .then(res => {
                Vue.toasted.success('Cabinet updated.');
                this.getCabinetsByManufacturer();

                // this.temp={};
                this.loading = false;
            })
            .catch(err => {

                if(err.response.data.title) {
                    Vue.toasted.error(err.response.data.title); 
                } else {
                    Vue.toasted.error('There was an error processing your request'); 
                }

                this.loading = false;

                });
        },

        updateCabinetImages(cabinet,media,keepOpen){
           
            this.loading = true;
            var formData = new FormData();

            //Setting image dimensions 
            //before passing object to API
            

            for(const key in media){
                if(media.hasOwnProperty(key)){
                    formData.append('File'+key, media[key])
                }
            }
           
            for(const key in this.mediaDim){
                if(this.mediaDim.hasOwnProperty(key)){
                    let value = this.mediaDim[key];
                    formData.append(key, value);
                }
            }


            axios.post('egmModels/'+cabinet.id+'/media', formData, {
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })
                .then(res=>{
                    console.log(res.data);
                    Vue.toasted.success('Item created.');
                    this.getCabinetsByManufacturer();
                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });

        },

        removeImage(cabinet, position){
            this.loading = true;
            let image ;
            var deleteImage = new FormData();
            if(position===0){
                image = 'DeleteLeft';
                this.selected.frontLeftMedia = null;
            }
            else if(position===1){
                image = 'DeleteFront' ;
                this.selected.frontMedia = null;
            }
            else {
                image = 'DeleteRight' ;
                this.selected.frontRightMedia = null;
            }

            deleteImage.append(image, 'true');

            axios.post('egmModels/'+cabinet.id+'/media', deleteImage)
                .then(res=>{
                    console.log(res.data);
                    Vue.toasted.success('Image deleted.');
                    this.getCabinetsByManufacturer();
                    
                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                });
        },

        deleteItem(item) {
            console.log(item);
            axios.delete('egmModels/' + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.getCabinetsByManufacturer();
                    this.$emit('refreshStats');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });
        },
    }
}
</script>
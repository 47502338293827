<template>
    <div class="progress-container">
        <div :style="{width:step*100/noOfSteps+'%'}" class="step-progress"></div>
        <div class="progress-bg"></div>
    </div>
</template>
<script>
export default {
    props:['step','noOfSteps'],
    data(){
        return {

        }
    }
}
</script>

<template>
    <div class="tab contacts" >
        <v-container>
            <v-row>
                <v-col xs='12' sm='12' md='4' lg='3' xl='3'>
                    <div class="card card-green" v-if="!dataContacts" data-aos="fade">
                        <crud-zone
                        label="Contacts"
                        :fields="fieldsContacts"
                        :data="dataContacts"
                        :canEdit="true"
                        :canAdd="true"
                        :canDelete="true"
                        />
                    </div>  
                </v-col>
                <v-col xs='12' sm='12' md='8' lg='9' xl='9'>
                    <div class="card card-green" v-if="!dataPeople" data-aos="fade">
                        <crud-zone
                        label="People"
                        :fields="fieldsPeople"
                        :data="dataPeople"
                        :canEdit="true"
                        :canAdd="true"
                        :canDelete="true"
                        />
                    </div>  
                </v-col>
            </v-row>
        </v-container>                
    </div>
</template>
<script>
import CrudZone from '@/components/CrudZone.vue';
export default {
	components: {
		'crud-zone': CrudZone
	},
    data(){
        return {
            dataContacts: null,
            dataPeople: null,
            fieldsContacts: null,
            fieldsPeople: null
        }
    }
}
</script>
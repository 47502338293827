<template>
    <span>
        <v-btn :elevation="0" class='game_desc_btn' @click='onClick(typeValue)'>{{typeValue.name}}</v-btn>
    </span>
</template>
<script>
export default {
    props:['typeValue'],
    data(){
        return{
            data:null,
        }
    },
    methods:{
        onClick(item){
            
            if(this.data === null){
                this.data = item;
                let obj = {
                    id : item.id,
                    data : this.data
                }
                this.$emit('typeValue', obj);
                
            }
            else{
                this.data = null;
                let obj = {
                    id : item.id,
                    data : this.data
                }
                this.$emit('typeValue', obj);
            } 
        }
    }
}
</script>